export default {
    beforeMount(el, binding) {

        const config = {
            onlyTime: false,
            hideYear: false,
            monthNumber: false,
            dayTitle: false,
            showTime: false,
            onlyDayTitle: false,
        };

        for(const m in binding.modifiers) {
            if(binding.modifiers[m] == true) {
                config[m] = true;
            }
        }

        let date = new Date(binding.value);
        let day = date.getDate();
        let year = date.getFullYear();
        let dayNames = [
            "neděle",
            "pondělí",
            "úterý",
            "středa",
            "čtvrtek",
            "pátek",
            "sobota",
        ];
        let monthNames = [
            "ledna",
            "února",
            "března",
            "dubna",
            "května",
            "června",
            "července",
            "srpna",
            "září",
            "října",
            "listopadu",
            "prosince"
        ];

        //let monthName = monthNames[date.getMonth()];

        //console.log('dev.directiveName format-date', config)
        let content = '';

        if(config.onlyDayTitle) {
            content += dayNames[date.getDay()];
        }else{
            if(config.dayTitle){
                content += dayNames[date.getDay()] + ' ';
            }

            content += day + '.'

            if(config.monthNumber){
                content += date.getMonth()
            }else{
                content += ' ' + monthNames[date.getMonth()]
            }
            if(!config.hideYear){
                content += ' ' + year
            }

            if(config.showTime){
                content += ' | ' + date.getHours() + ':' + (date.getMinutes() < 10? ('0'+date.getMinutes()): date.getMinutes())  ;
            }
        }

        if(config.onlyTime) {
            content = date.getHours() + ':' + (date.getMinutes() < 10? ('0'+date.getMinutes()): date.getMinutes());
        }


        el.innerHTML = content;
    }
}
<template >
  <select
      v-model="inputModel"
      class="form-select"
  >
    <option
      v-for="(item) in options"
      :value="item.id"
    >
      {{item.title}}
    </option>

  </select>

<!--
  <display-debug>
    <template #debugView>modelValue {{modelValue}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>options {{options}}</template>
  </display-debug>
  -->
</template>

<script>


import axios from "axios";

let timer;

export default {
  name: "WikiInputSelect",
  components: {

  },
  props: {
    "modelValue": {
      type: [String, Number, Object],
      required: false,
      default: null
    },
    "langs": [String, Object],
    "parentIndex": [String, Number],
    "options": {
      type: Object,
      required: false,
      default: {}
    }
  },
  emits: ['update:modelValue'],
  data() {
    return {
      writeStatus: false,
      inputModel: null,
    }
  },
  created() {

  },
  mounted() {
    this.inputModel = this.modelValue;
  },
  methods: {

  },
  watch: {
    "modelValue": {
      handler(newVal) {
        this.inputModel = newVal;
      },
      deep: true,
      immediate: true
    },
    inputModel: {
      handler(newVal) {
        this.$emit('update:modelValue', newVal )
      },
      deep: true,
      immediate: true
    },

  },
};
</script>
<template >
  <!--
  <quill-editor
      v-if="inputObj.params && inputObj.params.is_wysiwig"
      v-model:content="inputModel"
      content-type="html"
      theme="snow"
      :placeholder="(inputObj.params.placeholder)? inputObj.params.placeholder: ''"
      :toolbar="toolbar"

  />
  -->


  <!--  :modules="modules" -->
  <html-editor
      v-if="inputObj.params && inputObj.params.is_wysiwig"
      v-model="inputModel"
      editor-type="segment_odstavec"
  />
  <textarea
      v-else
      v-model="inputModel"
      class="form-control"
  ></textarea>


  <!--
  <pre v-if="inputObj.params && inputObj.params.is_wysiwig">
    TextAreaSegment.inputModel {{inputObj}}
  </pre>


  <pre v-if="inputObj.params && inputObj.params.is_wysiwig">
    {{toolbarOptions}}
  </pre>


  <pre>
    TextAreaSegment.inputModel {{inputModel}}
  </pre>
  <pre>
    TextAreaSegment.contentHTML {{contentHTML}}
  </pre
  -->
<!--
  <textarea
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
  ></textarea>
 -->

</template>

<script>
import { QuillEditor } from '@vueup/vue-quill'
import { QuillPlaceholderModule } from 'quill-placeholder-module'
//import { QuillMention} from 'quill-mention'
import { QuillHtmlEditButton} from 'quill-html-edit-button'
import '@vueup/vue-quill/dist/vue-quill.core.css';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
//import '@vueup/vue-quill/dist/vue-quill.bubble.css';

import htmlEditor from "@/components/content/htmlEditor";

export default {
  name: "TextAreaSegment",
  components: {
    QuillEditor,
    QuillPlaceholderModule,
    //QuillMention,
    QuillHtmlEditButton,
    htmlEditor,
  },
  props: {
    "segmentsObj": [Object, Array],
    "inputObj": [Object],
    "formObj": Object,
    "modelValue": [String, Object],
  },
  emits: ['update:modelValue'],
  setup() {
    const modules = {
    }

    const toolbar = 'full';
    return { toolbar }
    /*
    const customToolbar = {
      toolbar: ['clean', 'bold'],
    }


    return { customToolbar }
    */

  },
  data() {
    return {
      toolbarOptions: [],
      //contentHTML: '<h1>contentHTML This is header</h1><p>This is paragraph</p>',
      inputModel: null,
    }
  },

  mounted() {
    this.inputModel = this.inputObj.obsah;
    this.toolbarOptions = [
      'bold', 'italic', 'underline', 'strike',        // toggled buttons
      /*
      ['blockquote', 'code-block'],

      [{ 'header': 1 }, { 'header': 2 }],               // custom button values
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
      [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
      [{ 'direction': 'rtl' }],                         // text direction

      [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
      [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

      [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
      [{ 'font': [] }],
      [{ 'align': [] }],

      ['clean']  */                                       // remove formatting button
    ];
  },
  methods: {

  },
  watch: {
    inputObj: {
      handler() {
      //  this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){
      //console.log("dev inputModel TextAreaSegment xxxx", newVal)
      this.$emit('update:modelValue', newVal )
    },
  },
};
</script>
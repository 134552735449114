<template >
  <div >
    <div
        v-for="(langItem, langIndex)  in inputObj.langs"
    >
      <!--
      <div v-html="langItem.svg" style="max-height: 30px;"></div>
      -->

      <label :for="inputObj.name + '_lang_' + langIndex">
        <country-flag
            :country='langItem.flag'
            size="small"
        />
        <span class="ms-2 d-inline-block">{{langItem.title}}</span>
      </label>
      <input
          type="text"
          v-model="inputModel[langIndex]"
          :id="inputObj.name + '_lang_' + langIndex"
          :required="this.inputObj.required"
          class="form-control"
          @input="is_input_clicked = true;"
          @focus="setupLocked(langIndex, $event.target.value)"
          :maxlength="(inputObj.params && inputObj.params.maxlength)? inputObj.params.maxlength : 254"
      />
    </div>
  </div>


  <version-control-input
      v-if="inputObj.versions"
      v-model="inputModel"
      :versions="inputObj.versions"
  />



  <!--
  <display-debug>
    <template #debugView>is_input_clicked: {{is_input_clicked}}</template>
  </display-debug>
  -->
</template>

<script>
import {isObjectEmpty} from "@/utils/general";


let timer;
import CountryFlag from "vue-country-flag-next"
import axios from "axios";
import VersionControlInput from '@/components/general/form/inputs/_common/VersionControl'

export default {
  name: "TextInputLang",
  components: {
    CountryFlag,
    VersionControlInput
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": Object, Array,
  },
  data() {
    return {
      inputModel: null,
      timer: null,
      timer_delay: 700,
      inputStatus: {},
      is_input_clicked: false,
    }
  },
  computed: {
    watchedVariable() {
      return this[this.variableName];
    },
    slugSource() {

      if(
          typeof this.inputObj.options != "undefined"
          && typeof this.inputObj.options.is_slug != "undefined"
          && this.inputObj.options.is_slug
      ){
        const path = 'formObj.' + this.inputObj.options.slug_source_ident + '.val';
        return this.getNestedValue(this, path);
      }

    }
  },
  mounted() {
    this.inputModel = this.inputObj.val;
    if(!this.isObjectEmpty(this.inputObj.val) ){
      this.is_input_clicked = true;
    }
  },
  methods: {
    getNestedValue(obj, path) {
      return path.split('.').reduce((o, i) => (o ? o[i] : null), obj);
    },
    setupLocked(lang, val){
      this.inputStatus[lang] = true;
    },
    createSlugFormat(input){
      if(input) {

        const translate_map = {
          'á': 'a',
          'ä': 'a',
          'å': 'a',
          'č': 'c',
          'ç': 'c',
          'ď': 'd',
          'é': 'e',
          'ě': 'e',
          'ë': 'e',
          'í': 'i',
          'ň': 'n',
          'ó': 'o',
          'ö': 'o',
          'ř': 'r',
          'š': 's',
          'ť': 't',
          'ú': 'u',
          'ů': 'u',
          'ü': 'u',
          'ý': 'y',
          'ž': 'z',
          '+': '-',
        }
        input = input.toLowerCase().trim()
        input =  input.replace(/[^A-Za-z]/g, x => translate_map[x] || x)
        input = input.replace(/[^\w\s-]/g, '')
        input = input.replace(/[\s_-]+/g, '-')
        input = input.replace(/^-+|-+$/g, '')
      }
      return input;
    },

    async formatSlug(value) {
      if(value) {
        for(const i in value) {
          if(value[i]) {
            await axios({
              url: this.getApiConfig('general.format_slug.path') + "?" + this.getApiConfig('general.format_slug.input.slug') +"=" + value[i],
              method: this.getApiConfig('general.format_slug.method'),
              withCredentials: true,
              headers: this.getRequestHeader(),
            }).then((res) => {
              if(res.data.status){
                if(res.data.response.slug && res.data.response.slug != "null"){
                  value[i] = res.data.response.slug;
                }
              }
            })
          }
        }
      }
    }
  },
  watch: {
    "slugSource": {
      handler(newVal) {
        if(!this.is_input_clicked) {
          this.inputModel = Object.assign({}, newVal) ;
        }
      },
      deep: true,
      immediate: true
    },
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel: {
      handler(newVal) {

        if(timer) clearInterval(timer);
        timer = setTimeout(
            () => {
              if(
                  typeof this.inputObj.options != 'undefined'
                  && typeof this.inputObj.options.is_slug != 'undefined'
                  && this.inputObj.options.is_slug === true
              ) {
                this.formatSlug(newVal);
              }
              //update struktury, musi byt na konci
              this.inputObj.inputStatus = this.inputStatus
              this.inputObj.val = newVal
              this.$emit('update:modelValue', newVal )
            },
            this.timer_delay
        );

      },
      deep: true,
      immediate: true
    },
  },
};
</script>
<template>


  <modal-window
      :title="serverThubnailWindow.title"
      :config="serverThubnailWindow.config"
  >
    <template #modal-content-slot>

      <div class="w-100 text-center">
        <img
            :src="this.getBaseRoot() + this.getApiConfig('image.server_upload_thumbnail.path') + serverThubnailWindow.file +'&_h=' + userHash"
            alt="Nahled Obrazku"
            class="img-fluid"
        />
      </div>

    </template>
  </modal-window>


  <div class="w-100">
    <div class="mb-2">
      <div  v-if="is_gallery_browser" class="">
        <multiselect
            v-model="galerie.detail"
            :options="galerie.browser"
            track-by="id"
            :searchable="true"
            :hideSelected="false"
            :custom-label="formatGalerieBrowser"
            :placeholder="this.getText('image_component.placeholder_browser')"
            @search-change="galerieBrowser"
            @open="galerieBrowser"
        >
          <template v-slot:singleLabel="{ option }">
            <div style="display: inline-block; margin-left: 1rem;">
              <span v-if="option.title" class="fw-light"><i class="bi bi-images"></i> {{ option.title }}</span><br>
            </div>
          </template>

          <template v-slot:option="{ option, search }" >
            <div class="multiselect_custom gallery">
              <div class="block-desc">
                <strong v-if="option.title" >{{ option.title }}</strong><br>
                <span v-if="option.pocet" >{{ option.pocet }} obrazku</span><br>
                <span v-if="option.autor_title" >autor: {{ option.autor_title }}</span><br>
              </div>
            </div>
          </template>

          <template v-slot:noResult>
            <div class="multiselect--no-results">{{this.getText('image_component.multiselect.no_result')}}</div>
          </template>

          <template v-slot:noOptions>
            <div class="multiselect--no-results">{{this.getText('image_component.multiselect.no_result')}}</div>
          </template>
        </multiselect>
      </div>
      <div v-if="is_creating" class="d-grid d-md-flex">
        <a
            @click.prevent="galerie.is_new_form = !galerie.is_new_form"
            class="btn btn-outline-secondary mt-1 mt-sm-0"
        >
          <i class="bi-plus"></i> Založit novou galerii
        </a>
      </div>

    </div>
    <div  v-if="is_creating && galerie.is_new_form" class="row mb-2">
      <div class="col col-12 text-end">
        <div v-if="galerie.is_new_form" class="input-group mt-2">

          <input
              v-model="galerie.new_gallery.title"
              type="text"
              placeholder="Jmeno nove galerie"
              class="form-control"
              @input="preCreateNewGallery($event.target.value)"
          >
          <button
              @click.prevent="createNewGallery()"
              class="btn btn-outline-secondary"
              ref="btnNewGallery"
              disabled
          >
            Vytvořit
          </button>
        </div>
        <div
            v-if="galerie.new_gallery.err"
            class="alert alert-danger d-flex align-items-center mt-2"
            role="alert"
        >
          <svg class="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"><use xlink:href="#exclamation-triangle-fill"/></svg>
          <div>
            {{galerie.new_gallery.err}}
          </div>

        </div>
      </div>

    </div>

    <template v-if="galerie.detail">
      <div v-if="galerie.detail.is_editable"  class="container-fluid w-100 p-0">
        <div class="w-100 ">
          <!-- -->
          <draggable
              :list="galerie.images"
              itemKey="obrazek_id"
              handle=".move"
              ghost-class="ghost"
              xclass="d-flex flex-wrap"
              :class="(galerie.display != 'full')?'row row-cols-3 row-cols-sm-2 row-cols-md-3 row-cols-lg-6 row-cols-xxl-6 m-0 p-0 ':''"
          >
            <template #item="{ index, element }">
              <div
                  class="__move p-1"
              >
<!-- gallery-item -->
                <div
                    v-if="galerie.display == 'full'"
                    class="w-100 "
                >
                  <image-component
                      v-if="galerie.display == 'full'"
                      :image-id="element.obrazek_id"
                      type-id="1"
                      :is_editable_props="true"
                      :is_image_props="true"
                      :is_input_title="true"
                      :is_input_copyright="true"
                      :is_preview_only="false"
                      thumb_class="nahled"

                  />
                  <span class="move d-block w-100 text-end">
                    <i class="bi bi-arrows-move"></i>
                  </span>

                </div>
                <div
                    v-else
                    class="w-100"
                >
                  <image-component
                      :image-id="element.obrazek_id"
                      :is_preview_only="true"
                      thumb_class="none w-100"
                  />
                  <div class="d-flex mt-1">
                    <span @click.prevent="removeGalleryItem(index)" class="">
                      <i class="bi bi-trash"></i>
                      <span class="d-none d-sm-inline-block">Odstranit</span>
                    </span>
                    <span class="move ms-2 ms-auto">
                      <i class="bi bi-arrows-move"></i>
                    </span>
                  </div>

                </div>

              </div>
            </template>
          </draggable>
        </div>
        <div v-if="!galerie.images || galerie.images.length == 0" class="alert alert-danger text-center" role="alert">
          Galerie je prázdná
        </div>

        <div class="mt-2 text-start">
          <a v-if="galerie.display == 'image_only'" @click.prevent="galerie.display='full'" class="btn btn-secondary btn-sm"><i class="bi bi-receipt me-2"></i> Full zobrazeni</a>
          <a v-if="galerie.display == 'full'" @click.prevent="galerie.display='image_only'" class="btn btn-secondary btn-sm"><i class="bi bi-image me-2"></i> Pouze nahledy</a>
        </div>

        <div v-if="galerie.is_changed" class="mt-2">
          <button
              @click.prevent="saveGallery()"
              class="btn btn-secondary btn-sm"
          >
            Uložit změny v galerii.
          </button>
        </div>

        <div v-if="is_image_upload">
          <div class="input-group mt-2">
            <input
                type="file"
                ref="files_gallery_upload"
                accept="image/*"
                placeholder="vyber obrazky"
                multiple
                @change="imagePreupload($event.target.files)"
                class="form-control"
            >
            <button
                @click.prevent="imagesUpload()"
                ref="btnSubmit"
                disabled
                class="btn btn-outline-secondary"
            >
              <i class="bi-plus-circle"></i> Nahrát do galerie
            </button>
          </div>

          <div
              v-if="loadingStatus"
              class="alert alert-secondary"
              role="alert"
          >
            Nahrávám obrázky, počkejte moment.
          </div>

          <multiselect
              v-model="image.selected_id"
              :options="image.browser"
              track-by="id"
              :searchable="true"
              :hideSelected="false"
              :custom-label="formatImageBrowserItem"
              :placeholder="this.getText('image_component.placeholder_browser')"
              @search-change="image_browser"
              @open="image_browser"
              @select="onImageInput"
              class="border-0 p-0 mt-2"
          >
            <template v-slot:singleLabel="{ option }">
              <div style="display: inline-block;">
                <image-component
                    :image-id="option.id"
                    :is_preview_only="true"
                />
              </div>
              <div style="display: inline-block; margin-left: 1rem; background-color: #35495e">

                <strong v-if="option.title" style="font-size: 1.2rem;">{{ option.title }}</strong><br>
                <span v-if="option.copyright">copyright: {{ option.copyright }}</span><br>&nbsp;
                <span v-if="option.user_title">autor: {{ option.user_title }}</span>&nbsp;
              </div>
            </template>

            <template v-slot:option="{ option, search }">
              <div class="d-flex align-items-center">
                <image-component
                    :image-id="option.id"
                    :is_preview_only="true"
                    thumb_class="nahled"
                    class="flex-shrink-0 nahled"
                />
                <div class="flex-grow-1 ms-3">
                  <div v-if="option.title" class="fw-medium fs-5 mb-3">{{ option.title }}</div>
                  <div v-if="option.copyright" class="mb-1 text-secondary"><i class="bi bi-c-circle" style="font-size: .7rem;"></i> Copyright: {{ option.copyright }}</div>
                  <div v-if="option.user_title" class="text-secondary"><i class="bi bi-person"></i> {{ option.user_title }}</div>
                </div>
              </div>
            </template>

            <template v-slot:noResult>
              <div class="multiselect--no-results">Zadne vysledky pro tento dotaz</div>
            </template>

            <template v-slot:noOptions>
              <div class="multiselect--no-results">Zadne vysledky pro tento dotaz [noOption]</div>
            </template>
          </multiselect>
          <span v-if="imagesUploadErr" style="color: darkred;">{{imagesUploadErr}}</span>

        </div>

        <div
            v-if="is_image_upload_from_server"
            class="mt-2"
        >
          <a
              @click.prevent="server_upload.is_display_upload_form = !server_upload.is_display_upload_form; server_upload.is_uploading_complete = false; server_upload.is_uploading = false;"
              class="btn btn-outline-secondary btn-sm"
          >
            <i class="bi-upload"></i> Upload ze serveru
          </a>

          <div v-if="server_upload.is_display_upload_form">
            <ul
                v-if="server_upload.server_list && server_upload.server_list.length > 0  && !server_upload.is_uploading && !server_upload.is_uploading_complete"
                class="list-group"
            >
                <li
                    v-for="(imageItem, imageIndex)  in server_upload.server_list"
                    @key="imageIndex"
                    class="list-group-item form-check"
                >
                  <label
                      :for="'image_upload_' + imageItem.name"
                      class="form-check-label"
                  >
                    <input
                        v-model="server_upload.files_for_upload"
                        type="checkbox"
                        :id="'image_upload_' + imageItem.name"
                        :value="imageItem"
                        class="form-check-input"
                    >
                    {{imageItem.name}}

                    <span
                        @click.prevent="displayServerThumbnail(imageItem.name)"
                          class="ms-2 badge rounded-pill text-bg-warning"
                    >
                      <i class="bi-zoom-in"></i> náhled
                    </span>


                  </label>
                </li>
            </ul>
            <div v-else-if="!server_upload.is_uploading && !server_upload.is_uploading_complete">
              Na serveru nejsou žadné obrázku vhodné pro zpracování.
            </div>

            <div
                v-if="server_upload.server_list && server_upload.server_list.length > 0 && !server_upload.is_uploading && !server_upload.is_uploading_complete"
            >
              <button
                  v-if="server_upload.count_files_for_upload > 0"
                  class="btn btn-primary"
                  @click.prevent="serverUploading"
              >
                <i class="bi-upload"></i> Nahrát vybrané soubory ({{server_upload.count_files_for_upload}})
              </button>
              <a
                  @click.prevent="server_upload.files_for_upload = server_upload.server_list"
                  class="btn btn-link"
              >
                <i class="bi-plus-circle"></i>&nbsp; Vybrat všechny obrázky
              </a>
            </div>

            <div v-if="server_upload.is_uploading">
              <ul v-if="serverUploadListActive.length > 0">
                <li
                  v-for="(uploadItem, uploadIndex)  in serverUploadListActive"
                  @key="uploadIndex"
                >
                  {{uploadItem.name}}:
                  <span v-if="uploadItem.status=='start'">
                    <i class="bi-card-image"></i>  Právě nahravám obrázek
                  </span>
                  <span v-else-if="uploadItem.status=='err'">
                    CHYBA <pre>{{uploadItem.err}}</pre>
                  </span>
                  <span v-else>
                      <i class="bi-alarm"></i> Čeká ve frontě na zpracovaní.
                  </span>
<!--
[{{uploadItem.status}}]
                  <pre>{{uploadItem}}</pre>
-->
                </li>
              </ul>
            </div>


            <div
                v-else-if="server_upload.is_uploading_complete"
            >
              <div
                  v-if="server_upload.count_upload > 0"
              >

                <div
                    v-if="server_upload.err && server_upload.err.length > 0"
                    class="alert alert-secondary"
                >
                  Obrázky uspesne nahrány ({{server_upload.count_upload}}ks). Vyskytly se ale nasledujici chyby.
                </div>
                <div
                  v-else
                  class="alert alert-success"
                >
                  Všechny obrázky uspesne nahrány. Můžete pokračovat v editaci.
                </div>
              </div>

              <div
                  v-if="server_upload.err && server_upload.err.length > 0"
                  class="alert alert-danger" role="alert"
              >
                <h6 v-if="server_upload.err.length == 1">Chyba!</h6>
                <h6 v-else>Chyby!</h6>
                <div
                    v-for="(errItem, errIndex)  in server_upload.err"
                    @key="errIndex"
                >
                  {{errItem}}<br>
                </div>
              </div>
              //alerts
            </div>



            <!--
            <pre>{{server_upload.files_for_upload}}</pre>
            <pre>{{server_upload.count_files_for_upload}}</pre>
            <pre>{{server_upload}}</pre>
            -->
          </div>

        </div>



      </div>

      <div v-else class="row">
        <ul
            v-if="galerie.images && galerie.images.length > 0"
            class="col col-12 gallery"
        >
          <li
              v-for="(imageItem, imageIndex)  in galerie.images"
              @key="imageIndex"
              class="gallery-item"
          >
            <image-component
                :image-id="imageItem.obrazek_id"
                :is_preview_only="true"
            />
          </li>
        </ul>

        <div v-else class="alert alert-danger text-center" role="alert">
          Galerie je prázdná
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import multiselect  from 'vue-multiselect'
//import "vue-multiselect/dist/vue-multiselect.css"
import ImageComponent from "@/components/general/Image";
import texts from "@/components/config/texts"
import axios from "axios";
import draggable from "vuedraggable";
import ModalWindow from "@/components/content/ModalWindow";
let timer;
let gallery_images;
export default {
  name: "GalerieComponent",
  props: {
    "modelValue": [String, Number],
    "gallery_id": [String, Number],
    "image_typ_id": [String, Number],
    "is_gallery_browser": [Boolean],
    "is_image_upload": [Boolean],
    "is_image_upload_from_server": [Boolean],
    "is_image_preview": [Boolean],
    "is_image_browser": [Boolean],
    "is_full_view": [Boolean],
    "is_creating": [Boolean],
  },
  components: {
    multiselect,
    ImageComponent,
    ModalWindow,
    texts,
    draggable
  },
  emits: [
    'update:modelValue',
  ],
  data() {
    return {
      galerie: {
        id: null,
        detail: null,
        browser: [],
        is_changed: false,
        images: [],
        images_for_diff: [],
        diff_images: [],
        is_new_form: false,
        new_gallery: {
          title: null,
          err: null,
        },
        is_server_upload_form: false,
        server_upload_list: [],
        server_upload_select: [],
        display: 'image_only',
      },
      image: {
        selected_id: null,
        browser: [],
        image_upload: null,
      },
      server_upload: {
        is_display_upload_form: false,
        server_list: [],
        count_files_for_upload: 0,
        files_for_upload: [],
        is_uploading: false,
        is_uploading_complete: false,
        uploading_results: [],
        err: [],
        count_upload: 0,
      },
      loadingStatus: false,
      imagesUploadErr: null,
      serverThubnailWindow : {
        title: null,
        file: null,
        config: {
          "display": false,
          "is_header": true,
          "is_close_btn": true,
          "is_footer": true
        },
      },
      userHash: null,
    }
  },
  methods: {
    async galerieBrowser (query) {
      axios.defaults.baseURL = this.getBaseRoot();
      let apiPath = this.getApiConfig('gallery.browse_service.path');// this.cfg.api.gallery.browse_service.path

      if(query) apiPath += "?" + this.getApiConfig('gallery.browse_service.input.query') + "=" + query
      axios({
        url: apiPath,
        method: this.getApiConfig('gallery.browse_service.method'),
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        this.galerie.browser = res.data.response.list.data;
        //console.log("dev image browser data", res.data.response.list.data)
      })
          .catch((err) => {
            this.galerie.browser = []
            //console.log("DEV tags catch", err);
          })
    },
    formatGalerieBrowser ({ id, title, autor_title }) {
      return `${title} ${autor_title}   ${id}`;
    },
    async loadGalerieDetail(id){

      axios.defaults.baseURL = this.getBaseRoot();
      //let apiPath = this.cfg.api.gallery.preview.path +"?" + this.cfg.api.gallery.preview.input.id + "=" + id;
      let apiPath = this.getApiConfig('gallery.preview.path') + "?" + this.getApiConfig('gallery.preview.input.id') + "=" + id;
      axios({
        url: apiPath,
        //method: this.cfg.api.gallery.browse_service.method,
        method: this.getApiConfig('gallery.preview.method') ,
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        this.galerie.detail = res.data.response.data;
      })
      .catch((err) => {
        this.galerie.detail = [];
      })
    },
    async loadGalerieImages(id){
      //console.log("DEV loadGalerieImages", id)
      axios.defaults.baseURL = this.getBaseRoot();
      //let apiPath = this.cfg.api.gallery.images_service.path + "?" + this.cfg.api.gallery.images_service.input.query + "=" + id
      let apiPath = this.getApiConfig('gallery.images_service.path') + "?" + this.getApiConfig('gallery.images_service.input.query') + "=" + id

      axios({
        url: apiPath,
        method: this.getApiConfig('gallery.images_service.method'),
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        //console.log('dev loadGalerieImages', id, res.data.response.list)
        //this.browser_gallery_list = res.data.response.list.data;
        if(res.data.response.list) {
          this.galerie.images = res.data.response.list;
          this.galerie.diff_images = res.data.response.list;
        }else{
          this.galerie.images = [];
          this.galerie.diff_images = [];
        }

        //this.galerie.images_for_diff = res.data.response.list;
      })
      .catch((err) => {
        this.galerie.images = []
        this.galerie.diff_images = []
      })
      .finally(() => {
        this.galerie.is_changed = false;
        //console.log("DEV tags finally");
      })
    },

    async saveGallery () {
      //console.log("dev saveGallery", this.galerie.id, this.image_list)

      axios.defaults.baseURL = this.getBaseRoot();
      //let apiPath = this.cfg.api.gallery.save_service.path
      let apiPath = this.getApiConfig('gallery.save_service.path');
      let formData = new FormData();
      formData.append('galerie_id', this.galerie.id );
      formData.append('images',  JSON.stringify(this.galerie.images));
      axios({
        url: apiPath,
        data: formData,
        method: this.getApiConfig('gallery.save_service.method'),
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        //this.browser_image_list = res.data.response.list.data;
        //console.log("dev image browser data", res.data.response)
        this.galerie.is_changed = false;
      })
      .catch((err) => {
        //this.browser_image_list = []
        console.log("DEV tags catch", err);
      })
      .finally(() => {
        //console.log("DEV tags finally");
      })
    },

    async image_browser (query) {
      axios.defaults.baseURL = this.getBaseRoot();
      //let apiPath = this.cfg.api.image.browse_service.path + "?" + this.cfg.api.image.browse_service.input.type + "=" + this.image_typ_id
      //if(query) apiPath += "&" + this.cfg.api.image.browse_service.input.query + "=" + query
      let apiPath = this.getApiConfig('image.browse_service.path') + "?" + this.getApiConfig('image.browse_service.input.type') + "=" + this.image_typ_id
      if(query) apiPath += "&" + this.getApiConfig('image.browse_service.input.query') + "=" + query


      axios({
        url: apiPath,
        method: this.getApiConfig('image.browse_service.method'),
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        this.image.browser = res.data.response.list.data;
        //console.log("dev image browser data", res.data.response.list.data)
      })
      .catch((err) => {
        this.image.browser  = []
        console.log("DEV tags catch", err);
      })
    },

    formatImageBrowserItem ({ id, title, copyright, user_title }) {
      return `${title}  ${id} ${copyright} ${user_title}`
    },

    removeGalleryItem(index) {
      if (confirm("Chcete smazat polozku?") == true) {
        this.galerie.images.splice(index, 1);
      }
    },

    async imagesUpload(){
      //const fileList = this.$refs.files_gallery_upload;
      //console.log('dev gal imagesUpload', this.uploadFiles)

      if(this.uploadFiles && this.uploadFiles.length > 0) {
        for (let i = 0; i < this.uploadFiles.length; i++) {
          let formData = new FormData();
          this.loadingStatus = true;
          formData.append('image_file', this.uploadFiles[i] );
          formData.append('image_type', this.image_typ_id);
          axios.defaults.baseURL = this.getBaseRoot();
          await axios.post(
              this.getApiConfig('image.upload_service.path'), //this.cfg.api.image.upload_service.path,
              formData,
              {
                headers: this.getRequestHeaderUpload()
              }
          ).then((res) => {
            //console.log('gal upload ok', res.data)
            //console.log('gal upload ok err type', typeof res.data.err )
            if(typeof res.data.err == 'undefined') {
              /*
              this.modelValue
              if(!this.modelValue) {
                this.modelValue = {
                  "title": this.inputObj.params.title_prefill,
                  "images": []
                }
              }
              */
              this.galerie.images.push(
                  {
                    "obrazek_id": res.data.response.response.id
                  }
              )
              this.uploadFiles = [];
            }
          }).catch((err) =>{
            console.log('gal upload err', err)
          }).finally(() => {
            this.loadingStatus = false;
            this.uploadFiles = [];
          })
        }
        this.saveGallery();

        this.$refs['btnSubmit'].disabled = true;
        this.$refs['files_gallery_upload'].value = ''
      }
    },

    async imagePreupload(fileList) {
      const maxUploadCount = 20;
      if(fileList.length <= maxUploadCount) {
        this.uploadFiles = fileList
        let errArr = [];
        this.imagesErrArr = []
        for (let i = 0; i < fileList.length; i++) {
          let formData = new FormData();
          formData.append('image_file', fileList[i] );
          formData.append('image_type', this.image_typ_id);
          formData.append('image_validate', 1 );

          axios.defaults.baseURL = this.getBaseRoot();
          await axios.post(
              this.getApiConfig('image.upload_service.path'), //this.cfg.api.image.upload_service.path,
              formData,
              {
                headers: this.getRequestHeaderUpload()
              }
          ).then((res) => {
            if(typeof res.data.err == 'object') {
              errArr.push(fileList[i].name +": " + res.data.err.message);
            }
          }).catch((err) =>{
            errArr.push(fileList[i].name +": asi se rozbilo API")
          }).finally(() => {
            this.loadingStatus = false;
          })
        }

        if (errArr && errArr.length > 0) {
          this.$refs['btnSubmit'].disabled = true
          this.imagesUploadErr = errArr.join(", ");
        }else{
          this.imagesUploadErr = null;
          this.$refs['btnSubmit'].disabled = false
        }
      }else{
        this.imagesUploadErr = "Najednou lze nahrat pouze " + maxUploadCount + "obrazku, nyni se pokousite nahrat " + fileList.length + "ks. Pokud potrebuje tenahrat vic obrazku opakujte akci s dalsimi obrazky";
      }
    },

    onImageInput(selectedOption, id) {
      this.galerie.images.push(
          {
            "obrazek_id": selectedOption.id
          }
      )
      //console.log('dev saveGallery onImageInput', selectedOption, id)
      this.saveGallery();

      //this.modelValue.images.push(selectedOption.id)
      this.image.selected_id = null;
    },

    preCreateNewGallery(value){
      if(timer) clearInterval(timer);
      timer = setTimeout(
          () => {
            this.newGallery(value, false)
          },
          700
      )
    },

    createNewGallery() {
      if(this.galerie.new_gallery.title) {
        this.newGallery(this.galerie.new_gallery.title, true)
      }else{
        alert("Zadejte nazev nove galerie")
      }
    },

    async newGallery(title, is_writeable){
      let formData = new FormData();
      //formData.append(this.cfg.api.gallery.create_service.input.title, title );
      formData.append(this.getApiConfig('gallery.create_service.input.title'), title );


      if(!is_writeable) {
        //console.log('dev create gallery-validace Validace')
        //formData.append(this.cfg.api.gallery.create_service.input.validation, true);
        formData.append(this.getApiConfig('gallery.create_service.input.validation'), true);
      }


      axios.defaults.baseURL = this.getBaseRoot();
      await axios.post(
          this.getApiConfig('gallery.create_service.path'), //this.cfg.api.gallery.create_service.path,
          formData,
          {
            headers: this.getRequestHeaderUpload()
          }
      ).then((res) => {
        if(is_writeable) {
           let newGallery = res.data.response.detail
            newGallery.image = [];
            newGallery.is_editable = true;
            this.galerie.detail = newGallery;
            this.galerie.new_gallery.title = null
            this.$refs.btnNewGallery.disabled = true;
            this.galerie.is_new_form = false;
        }else{
          //validace

          if(res.data.response.validation_status && !is_writeable) {
            this.$refs.btnNewGallery.disabled = false;

            this.galerie.new_gallery.err = null;
          }else{
            this.galerie.new_gallery.err = res.data.response.err_message
            //console.log('dev create gallery-validace SUCC ERR',res.data.response.err_message)
          }
        }

      }).catch((err) =>{
        this.galerie.new_gallery.err = err
        console.log('dev create gallery ERR',err)
      }).finally(() => {
        //this.loadingStatus = false;
      })
    },

    async getServerUploadList(){
      axios.defaults.baseURL = this.getBaseRoot();
      let apiPath = this.getApiConfig('gallery.server_upload_list.path'); //this.cfg.api.gallery.server_upload_list.path
      axios({
        url: apiPath,
        method: this.getApiConfig('gallery.server_upload_list.method'),
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        this.server_upload.server_list = res.data.response.files;
        console.log("dev getServerUploadList data", res.data.response)
      })
      .catch((err) => {
        this.server_upload.server_list  = []
        console.log("DEV tags catch", err);
      })
    },

    async serverUploading() {

      if(this.server_upload.files_for_upload && this.server_upload.files_for_upload.length > 0) {
        this.server_upload.is_uploading = true;
        this.server_upload.is_uploading_complete = false;
        this.server_upload.count_upload = 0;
        this.server_upload.err = [];
        this.serverUploadeFile(0);
      }
    },

    serverUploadeFile(index) {
      if(this.server_upload.files_for_upload.length > index ) {
        if(this.server_upload.files_for_upload[index]) {
          //console.log('de serverUploadeFile UPLOAD FILE', index, this.server_upload.files_for_upload[index])
          this.server_upload.files_for_upload[index].status = "start"
          //todo upload file
          let formData = new FormData();
          /*
          formData.append(this.cfg.api.image.server_upload_service.input.name, this.server_upload.files_for_upload[index].name );
          formData.append(this.cfg.api.image.server_upload_service.input.file_path, this.server_upload.files_for_upload[index].path );
          formData.append(this.cfg.api.image.server_upload_service.input.image_type, this.image_typ_id);
          formData.append(this.cfg.api.image.server_upload_service.input.delete, true);
*/
          formData.append(this.getApiConfig('image.server_upload_service.input.name'), this.server_upload.files_for_upload[index].name );
          formData.append(this.getApiConfig('image.server_upload_service.input.file_path'), this.server_upload.files_for_upload[index].path );
          formData.append(this.getApiConfig('image.server_upload_service.input.image_type'), this.image_typ_id);
          formData.append(this.getApiConfig('image.server_upload_service.input.delete'), true);
          //TODO validace

          axios.defaults.baseURL = this.getBaseRoot();
          let apiPath = this.getApiConfig('image.server_upload_service.path')
          axios({
            url: apiPath,
            data: formData,
            method: this.getApiConfig('image.server_upload_service.method'),
            withCredentials: true,
            headers: this.getRequestHeader(),
          }).then((res) => {
            //this.server_upload.files_for_upload[i].status = 'processing';
            //this.server_upload.server_list = res.data.response.files;
            //console.log("dev getServerUploadList RES", res.data)
            if(res.data.status){
              //console.log("dev getServerUploadList data", res.data.response.response.id)
              this.server_upload.count_upload++;
              this.galerie.images.push(
                  {
                    "obrazek_id": res.data.response.response.id
                  }
              )
            }else{
              this.server_upload.err.push('Chyba soubor '+ this.server_upload.files_for_upload[index].name + ': ' + res.data.err.message);
              //console.log("dev getServerUploadList ERR", res.data.err.message)
            }

          })
          .catch((err) => {
                //this.server_upload.server_list  = []
                console.log("DEV tags catch", err);
                //this.server_upload.files_for_upload[i].err = err;
          }).finally((fin) => {
            this.server_upload.files_for_upload[index].status = "done"
            index++
            this.serverUploadeFile(index);
          })
        }
      }else{
        console.log('dev saveGallery END', index)
        this.saveGallery();
        this.server_upload.is_uploading = false;
        this.server_upload.is_uploading_complete = true;

      }
    },

    displayServerThumbnail(file){
      this.serverThubnailWindow.config.display = true;
      this.serverThubnailWindow.file = file;
      this.serverThubnailWindow.title = 'Náhled obrázku ' + file;
    },
  },
  computed: {
    serverUploadListActive() {
      return this.server_upload.files_for_upload.filter((item) => item.status != "done")
    },
  },
  created() {
    if(this.gallery_id) {
      this.galerie.id = this.gallery_id;
      this.loadGalerieDetail(this.gallery_id);
    }
    this.userHash = localStorage.getItem(this.getConfig('login_local_storage_name'))
  },
  watch: {
    "server_upload.files_for_upload": function (newVal, oldVal) {
      this.server_upload.count_files_for_upload = this.server_upload.files_for_upload.length;
    },
    "server_upload.is_display_upload_form": function (newVal, oldVal) {
        if(newVal) {
          this.getServerUploadList();
        }
    },

    "galerie.detail": function (newVal, oldVal) {
      if(newVal && newVal.id && newVal != oldVal) {
        //console.log("DEV_WATCH galerie.detail", newVal )
        this.galerie.id = newVal.id;
        this.loadGalerieImages(newVal.id);
        this.$emit('update:modelValue', newVal.id )
      }
    },

    "galerie.images":  {
      handler(newVal, oldVal) {
        this.galerie.is_changed = true;
        if(newVal && newVal.length > 0) {

          for (var i = 0; i< newVal.length; i++) {
            newVal[i].poradi = parseInt(i) + 100;
          }
        }
        //TODO dev rozdil
      },
      deep: true,
    },

    "galerie.images_for_diff": function (newVal) {
        //console.log('____ DEV_WATCH galerie.images_for_diff', newVal.length, newVal)
        /*
        JSON.stringify(this.arr1.sort()) === JSON.stringify(this.arr2.sort())
         */
    },
    "image.image_upload": function (newVal) {
      if(newVal) {
        //console.log('____ DEV_WATCH image_upload PRIDAVAM IMG')
        this.galerie.images.push({
          "obrazek_id": newVal
        })
        this.image.image_upload = null;
      }
    },
  }
};
</script>
<template>
  <div
      class="editor-wrap"
      ref="editorWrap"
  >
    <!-- wysiwig editor -->
    <div
        v-show="htmlEditor.display"
        class="wysiwig-wrap"
    >
      <div
          class="btns-wrap btn-nav-bar mb-1"
      >
        <div
            v-for="(button, index) in renderButtons"
            @click.prevent="onEditorButton(index, button)"
            :class="'btn btn-sm btn-outline-secondary me-1 mb-1' + (button.isActive? ' active': '') + (button.isDisabled? ' disabled': '')"
        >
          <i
              v-if="button.ico.class"
              :class="button.ico.class"
          >
          </i>

          <span
              class="ms-1 d-none d-md-inline"
          >
            {{button.title}}
          </span>

          <i
              v-if="button.isActive"
              class="bi bi-eraser-fill ms-1"
          />
          <!--
          | isA: {{button.isActive}} | isD: {{button.isDisabled}}
          -->

        </div>

        <span class="text-muted">Specialni:</span>

        <div
            @click.prevent="clearFormat()"
            class="btn btn-sm btn-outline-secondary me-1 mb-1"
        >
          <i class="bi bi-trash"/>
          <span
              class="ms-1 d-none d-md-inline"
          >
              Vycistit format
            </span>
        </div>
        <div
            @click.prevent="onToggleFullscreen"
            class="btn btn-sm btn-outline-secondary me-1 mb-1"
        >
          <i class="bi bi-fullscreen"/>
          <span
              class="ms-1 d-none d-md-inline"
          >
              Fullscreen
            </span>
        </div>


        <template
            v-for="(button, index) in specialButton"
        >
          <div
              v-if="button.isDisplayed"
              @click.prevent="onSpecialButton(index, button)"
              :class="'btn btn-sm btn-outline-secondary me-1 mb-1' + (button.isActive? ' active': '') + (button.isDisabled? ' disabled': '')"
          >
            <i
                v-if="button.ico.class"
                :class="button.ico.class + ' me-1'"
            >
            </i>
            <span
                class="ms-1 d-none d-md-inline"
            >
              {{button.title}}
            </span>

          </div>
        </template>

      </div>

      <div
          class="content-wrap "
      >
        <div
            v-if="dialog && dialog.display"
            class="layer layer-top"
        >
          <div class="layer-wrap">
            <div class="layer layer-top center-center">
              <div class="modal form-wrap form-overflow">
                <div class="modal-dialog">
                  <div class="modal-content">
                    <div class="modal-header">
                      <h5 class="modal-title">{{dialog.title}}</h5>
                      <button
                          @click.prevent="closeDialog();"
                          type="button" class="btn-close btn-sm"
                      ></button>
                    </div>
                    <div class="modal-body">

                      <div
                          v-for="(item, index) in dialog.inputs"
                      >
                        <template
                            v-if="item.type == 'text'"
                        >
                          <div
                              class="input-group mb-2"
                          >
                            <label class="input-group-text">{{item.title}}</label>
                            <input
                                type="text"
                                v-model="this.elementData.params[index]"
                                :required="item.isMandatory? 'required': ''"
                                class="form-control"
                            />
                          </div>
                       </template>
                        <template
                           v-else-if="item.type == 'select'"
                       >
                         <div
                             class="input-group mb-2"
                         >
                           <label class="input-group-text">{{item.title}}</label>
                           <select
                               v-model="this.elementData.params[index]"
                               class="form-select"
                           >
                             <option
                                 v-for="(option, index) in item.select"
                                 :value="index"
                             >
                               {{option.title}}
                             </option>
                           </select>
                         </div>
                       </template>
                        <template
                          v-else-if="item.type == 'bool'"
                      >
                        <div
                            class="form-check form-switch mb-2"
                        >
                          <input
                              v-model="this.elementData.params[index]"
                              :id="'special_dialog_' + index"
                              type="checkbox"
                              placeholder="vypln text"
                              class="form-check-input"
                              value="1"
                          >
                          <label
                              :for="'special_dialog_' + index"
                              class="form-check-label"
                          >
                            {{item.title}}
                          </label>
                        </div>
                      </template>
                        <template
                          v-else-if="item.type == 'btn_submit'"
                      >
                        <div
                            class="-btn-group mb-2"
                        >
                          <template
                              v-for="(btn) in item.content"
                          >
                            <div
                                :class="'btn me-1 ' + ((this.elementData.params[index] == btn.value)? 'btn-primary': 'btn-outline-primary')"
                                @click.prevent="this.elementData.params[index]=btn.value;applyDialog(dialog);"
                            >
                              {{btn.title}}
                            </div>
                          </template>
                        </div>

                      </template>
                        <template
                        v-else
                      >
                        <display-debug>
                          <template #debugView>input item bez definice {{item}}</template>
                        </display-debug>
                      </template>

                        <div
                            v-if="item.err"
                            class="bg-danger"
                        >
                          {{item.err}}
                        </div>
                      </div>
                    </div>

                    <div
                        v-if="dialog.btn_footer"
                        class="modal-footer"
                    >
                      <button
                          @click.prevent="applyDialog(dialog)"
                          class="btn btn-sm btn-success me-2"
                      >
                        <i class="bi bi-check2"></i> Aplikovat
                      </button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="layer layer-bottom background-fill">
              &nbsp;
            </div>
          </div>
        </div>
        <div
            class="layer layer-bottom p-1 form-parent"
        >
          <div
              ref="htmlEditorBody"
              contenteditable="true"
              class="form-control p-3"
              @input="onEditorInput()"
              @click="updateTextSelection()"
              @keydown="updateTextSelection()"
              @paste="onEditorPaste"
              @keypress="onEditorKeyEnter"
          />
        </div>
      </div>
    </div>

    <!-- code editor -->
    <div
        v-show="!htmlEditor.display"
    >
        <textarea
            v-model="inputModel"
            class="form-control html-code"
            @input="onCodeInput()"
            :NOTstyle="codeEditorStyle"
        />
    </div>

    <!-- switch wysiwig/code -->
    <div class="text-end">
      <span
          @click.prevent="switchView()"
          class="btn btn-secondary btn-sm"
      >
        <span
            v-if="htmlEditor.display"
        >
          <i class="bi bi-code"></i>
          [prepnout na code]
        </span>
        <span
            v-else
        >
          <i class="bi bi-easel"></i>
          [prepnout na editor]
        </span>

      </span>
    </div>

  </div>







<!--
  <display-debug>
    <template #debugView>definiceBtns {{renderButtons}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>elementData {{elementData}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>contentSelection {{contentSelection}}</template>
  </display-debug>


  <display-debug>
    <template #debugView>htmlEditor {{htmlEditor}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>modelValue {{modelValue}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>inputModel {{inputModel}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>search {{search}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>btnDialog {{btnDialog.values}}</template>
  </display-debug>
-->

</template>

<script>

import axios from "axios";
import "./htmlEditor.scss";
const htmlEditorConst = {
  attributes: {
    element_id_name: 'data-cms-element-id',
    element_typ_name: 'data-cms-element-type',
    element_values_name: 'data-cms-element-form-values',
  },
  action: {
    create: 'create',
    edit: 'edit',
    delete: 'delete',
  }
}
export default {
  name: "htmlEditor",
  props: {
    "modelValue": {
      type: [String, Number, Object],
      required: false,
    },
    "editorType": {
      type: [String, Number],
      required: false,
    },
  },
  emits: ['update:modelValue'],
  components: {

  },
  data() {
    return {
      inputModel: null,
      htmlEditor: {
        display: true,
        textareaHeight: 50,
        ref: null,
      },
      contentSelection: {
        action: null,
        selection: null,
        isCollapsed: null,
        range: null,
        elementNode: null,
        rangeEdited: null,
      },
      elementData: {
        button: null,
        tag: null,
        wrappedText: null,
        params: {},
        child: null,
      },
      dialog: null,
      definiceBtns: {
        /*
        x: {
          isActive: false,
          isDisabled: false,
          isWrap: false,
          methods: {
            insert: null,
            dialog: null,
          },
          params: {
            x: {
              value: null,
              readonly: false,
              default: null,
            }
          },
          dialog: {
            title: 'nazev dialogu',
            inputs: {
              x: {
                title: 'nazev pole',
                type: 'text', // select, boolean, checkbox
                isMandatory: false,
                //todo naplneni selectu1
              }
            },
          }
        },
            */
        a: {
          title: 'Odkaz',
          ico: {
            class: 'bi bi-link'
          },
          isActive: false,
          isDisabled: false,
          isWrap: true,
          tagName: 'a',
          params: {
            href: {
              value: null,
              readonly: false,
              default: null,
            },
            class: {
              value: null,
              readonly: false,
              default: null,
            },
            target: {
              value: null,
              readonly: false,
              default: '_self',
            },
          },
          dialog: {
            title: 'Odkaz',
            btn_footer: true,
            inputs: {
              href: {
                title: 'Link',
                type: 'text', // select, boolean, checkbox
                isMandatory: true,
                //todo naplneni selectu1
              },
              target: {
                title: 'Okno',
                type: 'select', // select, boolean, checkbox
                isMandatory: false,
                select: {
                  '_self': {
                    'title': 'Vlastni'
                  },
                  '_blank': {
                    'title': 'Nove'
                  },
                }
                //todo naplneni selectu1
              },
            },
          }
        },
        strong: {
          title: 'Tucne',
          ico: {
            class: 'bi bi-type-bold'
          },
          isActive: false,
          isDisabled: false,
          isWrap: true,
          tagName: 'strong',
          params: {
            href: {
              value: null,
              readonly: false,
              default: null,
            },
          },
        },
        italic: {
          title: 'Kurziva',
          ico: {
            class: 'bi bi-type-italic'
          },
          isActive: false,
          isDisabled: false,
          isWrap: true,
          tagName: 'i',
        },
        underline: {
          title: 'Underline',
          ico: {
            class: 'bi bi-type-underline'
          },
          isActive: false,
          isDisabled: false,
          isWrap: true,
          tagName: 'u',
        },

        ul: {
          title: 'list',
          ico: {
            class: 'bi bi-list-ul'
          },
          isActive: false,
          isDisabled: false,
          isWrap: true,
          tagName: 'ul',
          child: [
            {
              tagName: 'li',
              _content: 'obsah',
              params: {
                class: 'first-li-filled'
              },
            },
            {
              tagName: 'li',
              content: 'obsah druheho',
              params: {
                class: 'first-li-second'
              },
            },
          ],
          params: {
          },
        },
        ol: {
          title: 'Cislovany seznam',
          ico: {
            class: 'bi bi-list-ol'
          },
          isActive: false,
          isDisabled: false,
          isWrap: true,
          tagName: 'ul',
          child: [
            {
              tagName: 'li',
              _content: 'obsah',
              params: {
                class: 'first-li-filled'
              },
            },
            {
              tagName: 'li',
              content: 'obsah druheho',
              params: {
                class: 'first-li-second'
              },
            },
          ],
          params: {
          },
        },
        hr: {
          title: 'hor. linka',
          ico: {
            class: 'bi bi-dash'
          },
          isActive: false,
          isDisabled: false,
          isWrap: false,
          tagName: 'hr',
          params: {
            x: {
              value: null,
              readonly: false,
              default: null,
            }
          },
        },
        artist: {
          title: 'Umelec',
          ico: {
            class: 'bi bi-person-raised-hand'
          },
          isActive: false,
          isDisabled: false,
          isWrap: true,
          /*
          methods: {
            name: 'markArtist',
            //insert: 'artistInsert', //TODO Obsolete
            dialog: 'artistDialog',  //TODO Obsolete
            params: {
              config_path_ident: 'dev',
              dev_param: 'xxxxx'
            }
          },

          tagName: 'a',
          params: {
            class: {
              value: null,
              readonly: false,
              default: 'border border-danger',
            }
          },
          */
          dialog: {
            title: 'Oznaceni umelce',
            btn_footer: false,
            type: 'dynamic', // static | dynamic | null
            config: {
              pathIdent: 'dev',
              createMethod: 'buttonActionDynamicCreate',
              validationMethod: 'validaceOznaceniUmelce',
            },
            inputs: {
              x: {
                title: 'nazev pole pro umelce',
                type: 'text', // select, boolean, checkbox
                isMandatory: false,
                //todo naplneni selectu1
              }
            },
          }
        },


        p_left: {
          title: 'Nalevo',
          ico: {
            class: 'bi bi-justify-left'
          },
          isActive: false,
          isDisabled: false,
          isWrap: true,
          tagName: 'p',
          params: {
            style: {
              value: 'text-align: left;',
              readonly: false,
              default: null,
            },
          },
        },
        p_right: {
          title: 'Napravo',
          ico: {
            class: 'bi bi-justify-right'
          },
          isActive: false,
          isDisabled: false,
          isWrap: true,
          tagName: 'p',
          params: {
            style: {
              value: 'text-align: right;',
              readonly: false,
              default: null,
            },
          },
        },
        p_justify: {
          title: 'Do bloku',
          ico: {
            class: 'bi bi-justify'
          },
          isActive: false,
          isDisabled: false,
          isWrap: true,
          tagName: 'p',
          params: {
            style: {
              value: 'text-align: justify;',
              readonly: false,
              default: null,
            },
          },
        },
        /*
        artist: {
          title: 'Umelec',
          ico: {
            class: 'bi bi-person-raised-hand'
          },
          isActive: false,
          isDisabled: false,
          isWrap: true,
          methods: {
            name: 'markArtist',
            //insert: 'artistInsert', //TODO Obsolete
            dialog: 'artistDialog',  //TODO Obsolete
            params: {
              config_path_ident: 'dev',
              dev_param: 'xxxxx'
            }
          },
          tagName: 'a',
          params: {
            class: {
              value: null,
              readonly: false,
              default: 'border border-danger',
            }
          },
          dialog: {
            title: 'dialog umelec',
            inputs: {
              x: {
                title: 'nazev pole',
                type: 'text', // select, boolean, checkbox
                isMandatory: false,
                //todo naplneni selectu1
              }
            },
          }
        },
*/

      },

      btnDialog: {
        display: false,
        action: null,
        values: {},
        err: {
          status: false,
          message: null,
        },
      },
      specialButton: {
        eraseTag : {
          isDisplayed: false,
          isDisabled: false,
          method: 'removeOtherElement',
          title: 'Odstranit element',
          ico: {
            class: 'bi bi-trash'
          },
        },
        clearSelection : {
          isDisplayed: true,
          isDisabled: false,
          method: 'clearSelection',
          title: 'Odstranit formatovani',
          ico: {
            class: 'bi bi-eraser'
          },
        },
        editorSwitchToCode : {
          isDisplayed: true,
          isDisabled: false,
          method: 'switchEditor',
          title: 'Prepnout na kod',
          ico: {
            class: 'bi bi-code'
          },
          data: {
            status: true,
          }
        },
        editorSwitchToWYSIWYG : {
          isDisplayed: false,
          isDisabled: false,
          method: 'switchEditor',
          title: 'Prepnout na editor',
          ico: {
            class: 'bi bi-code-slash'
          },
        },
      },
    }
  },

  created() {
    //console.clear() //DEV
  },

  mounted() {


    this.htmlEditor.ref = this.$refs.htmlEditorBody;

    //TODO Nacteni btn ze souboru wysiwyg.buttons.path

    //this.onMounted();
    //TODO nevim proc
    setTimeout(() => {
      this.onMounted();
    }, 50);
  },
  computed: {
    renderButtons() {

      const buttons = Object.assign(this.definiceBtns);

      for(const i in buttons) {

        buttons[i].key = i;
        if(buttons[i].isActive === true){

          buttons[i].isDisabled = false;
        }else{

          if(this.contentSelection.isCollapsed !== null) {
            if(buttons[i].isWrap === true){
              if(this.contentSelection.isCollapsed === true){
                buttons[i].isDisabled = true;
              }else{
                buttons[i].isDisabled = false;
              }
            }else{
              if(this.contentSelection.isCollapsed === true){
                buttons[i].isDisabled = false;
              }else{
                buttons[i].isDisabled = true;
              }
            }
          }else{
            buttons[i].isDisabled = true;
          }
        }
      }
      return buttons;
    },

    codeEditorStyle() {
      let h = 0;
      const  rowHeight = 12;
      //
      if(this.inputModel ){
        h = this.inputModel.length/45 * rowHeight;
      }

      if(h < 100){
        h = 100;
      }
      return 'height: ' + Math.round(h)  + 'px;';
    },
  },

  methods: {
    onMounted(){
      if(this.editorType) {
        axios.defaults.baseURL = this.getBaseRoot();
        axios({
          method: 'get',
          url: this.getApiConfig('wysiwyg.buttons.path') +'?type=' + this.editorType,

          withCredentials: true,
          headers: this.getRequestHeader(),
        }).then((res) => {
          try {
            if(
                res.data.response.buttons
                && res.data.response.buttons.lenght > 0
            ){
              this.definiceBtns = res.data.response.buttons;
            }
            //console.log('[htmlEditor.onMounted] editorType DATA', res.data.response.buttons)
          } catch (e) {
            console.log('[htmlEditor.onSpecialButton] ERR',  e )
          }
        }).catch(function(err){
          console.log("dev onMounted buttons catch", err)
        })
      }


      this.inputModel = this.modelValue;
      this.htmlEditor.ref.innerHTML = this.modelValue;
    },
    switchView(){
      this.htmlEditor.display =! this.htmlEditor.display;
    },
    onEditorInput(){
      //console.log('[htmlEditor.onEditorInput]')
      this.inputModel = this.htmlEditor.ref.innerHTML; //naplneni promene
      this.optimizeCode();

    },
    onCodeInput(){
      console.log('[htmlEditor.onCodeInput]')
      this.htmlEditor.ref.innerHTML = this.inputModel;
      this.optimizeCode();
    },
    onEditorBlur() {
      console.log('[htmlEditor.onEditorBlur] ', this.contentSelection  )
    },


    onEditorPaste(event) {
      event.preventDefault();
      /*
      const plainText = event.clipboardData.getData('text/plain');

      const newElement = document.createTextNode(plainText);
      const myRange = this.contentSelection.range
      myRange.deleteContents();
      myRange.insertNode(newElement);
      myRange.collapse(true);
      */

      const htmlText = event.clipboardData.getData('text/html');
      this.pasteHtml(htmlText)
      this.onEditorInput();
    },

    pasteHtml(html) {
      const range = document.createRange();
      range.selectNode(document.body);
      const documentFragment = range.createContextualFragment(html);

      documentFragment.childNodes.forEach(child => {
        if(
            child.nodeType != Node.TEXT_NODE
            && child.nodeType != Node.ELEMENT_NODE
        ) {
          documentFragment.removeChild(child);
        }
      });

      this.cleanPastedChild(documentFragment);

      const myRange = this.contentSelection.range
      myRange.deleteContents();
      myRange.insertNode(documentFragment);
      myRange.collapse(true);
    },

    cleanPastedChild(parent){
      if(parent.nodeType == Node.ELEMENT_NODE) {
        parent.removeAttribute('style');
      }else{
        console.log('[htmlEditor.pasteHtml] cleanPastedChild ELSE',  parent.nodeType,  parent.childNodes, parent);
      }

      parent.childNodes.forEach(child => {
        if(child.nodeType == Node.ELEMENT_NODE) {
          child = this.cleanPastedChild(child);
        }
      });
      return parent;
    },




    onToggleFullscreen() {
      //TODO
      const element = this.$refs.editorWrap;

      //this.is_fullscreen = true;
      if (!document.fullscreenElement) {
        element.requestFullscreen().catch(err => {
          //this.is_fullscreen= false;
          console.error(`Error attempting to enable fullscreen mode: ${err.message} (${err.name})`);
        });
      } else {
        document.exitFullscreen();
        console.log('onToggleFullscreen exit' )
        //this.is_fullscreen = false;
      }
    },

    onEditorKeyEnter(event) {
      if(event.charCode == 10) {
        if (event.ctrlKey == true ) {
          event.preventDefault();
          if(
              this.contentSelection.elementNode
              && this.contentSelection.elementNode.parentNode
          ) {
            if(
                this.contentSelection.elementNode.parentNode.tagName == 'UL'
                || this.contentSelection.elementNode.parentNode.tagName == 'OL'
            ) {

              var index = Array.prototype.indexOf.call(
                  this.contentSelection.elementNode.parentNode.children,
                  this.contentSelection.elementNode
              );
              console.log('[htmlEditor.onEditorKeyEnter] PORADI', index, this.contentSelection.elementNode.parentNode.children.length, this.contentSelection.elementNode )

              const listItem = document.createElement('li')
              //listItem.innerText = 'aaaaa'

              if((parseInt(index)+1) ==  this.contentSelection.elementNode.parentNode.children.length) {
                this.contentSelection.elementNode.appendChild(listItem);
                this.moveCursorToElement(
                    listItem
                );
              }else{

                this.contentSelection.elementNode.parentNode.insertBefore(
                    listItem,
                    this.contentSelection.elementNode.parentNode.children[(index+1)]
                );


                this.moveCursorToElement(
                    this.contentSelection.elementNode.parentNode.children[(index+1)]
                );


               }
            }
          }
        }
      }

      if(event.charCode == 13) {
        event.preventDefault();

        const newElement = document.createElement('br')
        const myRange = this.contentSelection.range
        myRange.deleteContents();
        myRange.insertNode(newElement);
        myRange.collapse(false);
        //this.moveCursorToElement(newElement)

      }

      this.onEditorInput();
    },

    moveCursorToElement(element) {
      var range = document.createRange();
      //var element = this.contentSelection.elementNode.parentNode.children[(index+1)];
      range.setStart(element, 0);
      range.setEnd(element, 0);
      this.contentSelection.selection.removeAllRanges();
      this.contentSelection.selection.addRange(range);
    },


    _insertBefore(newNode, referenceNode) {

    },

    onSpecialButton(index, button) {
      if(button.method) {
        const method = button.method;
        try {
          //console.log('[htmlEditor.onSpecialButton] ', button.method )
          this[method](button)
        } catch (e) {
          console.log('[htmlEditor.onSpecialButton] ERR',  e )
        }
      }
    },

    onEditorKeyDetect(event) {
      console.log('[htmlEditor.onEditorKeyDetect] ', event )
    },

    optimizeCode() {
      //todo delay?
      /*
      const editorData = this.htmlEditor.ref;
      const codeData = this.htmlEditor.ref.innerHTML ;


      editorData.querySelectorAll('.deleted').forEach(function(deleted) {
        //console.log('[htmlEditor.optimizeCode] deleted', deleted )
        const testText = deleted;
        console.log('[htmlEditor.optimizeCode] deleted ITEM', testText, deleted.innerHTML )
        //codeData.replace(new RegExp(testText, 'g', deleted.innerHTML));
        const dev = codeData.replace(new RegExp(testText, 'g', '[deletee]'));
        //.log('[htmlEditor.optimizeCode] dev', dev )
      });


*/

      //console.log('[htmlEditor.optimizeCode] ', editorData.innerHTML )
      //console.log('[htmlEditor.optimizeCode] codeData ', codeData )
      /*
      editorData.querySelectorAll('div').forEach(function(div) {

        if (div.children.length === 1 && div.children[0].tagName === 'BR') {

          console.log('[htmlEditor.optimizeCode] Nalezen <div> obsahující pouze <br/>:', div);

          //const testText = deleted;
          editorData.replace(new RegExp(div.innerHTML, 'g', '__nl__'));
        }
      });
*/

    //delete <div><br></div>

      //delete span[data-cms-special-delete]


    },

    removeOtherElement(button) {
      console.log('[htmlEditor.removeOtherElement] ', button )
    },

    clearSelection(button) {
      //console.log('[htmlEditor.clearSelection] ', button )
      console.log('[htmlEditor.clearSelection] contentSelection', this.contentSelection )

      if(this.contentSelection.elementNode) {
        const myRange = new Range();
        myRange.selectNode(this.contentSelection.elementNode);
        myRange.deleteContents();
        let nodes = [];

        if(this.contentSelection.elementNode.hasChildNodes()) {
          this.contentSelection.elementNode.childNodes.forEach(node => {
            if (node.nodeType == Node.TEXT_NODE) {
              const textNodeAppend = document.createTextNode(node.nodeValue);
              nodes.push(textNodeAppend)
            }else {
              nodes.push(node);
            }
          });
        }

        if(nodes && nodes.length) {
          nodes = nodes.reverse();
          for (const n in nodes){
            myRange.insertNode(nodes[n]);
          }
        }

        this.dialog = null;
      }
      this.onEditorInput();
    },

    onEditorButton(index, button) {
      console.log('[htmlEditor.onButton]', button.isActive )
      if(button.isActive){
        //odstraneni
        this.contentSelection.action = htmlEditorConst.action.delete;
        this.onEditorButtonRemoveElement(index, button)
      }else{
        //pridani
        this.contentSelection.action = htmlEditorConst.action.create;
        this.onEditorButtonCreateElement(index, button)
      }
    },

    onEditorButtonRemoveElement(index, button) {
      console.log('[htmlEditor.onEditorButtonRemoveElement]',index, button )
      if(this.contentSelection.elementNode) {
          const myRange = new Range();
          myRange.selectNode(this.contentSelection.elementNode);
          myRange.deleteContents();
          let nodes = [];

          if(this.contentSelection.elementNode.hasChildNodes()) {
            this.contentSelection.elementNode.childNodes.forEach(node => {
              if (node.nodeType == Node.TEXT_NODE) {
                const textNodeAppend = document.createTextNode(node.nodeValue);
                nodes.push(textNodeAppend)
              }else {
                nodes.push(node);
              }
            });
          }

        if(nodes && nodes.length) {
          nodes = nodes.reverse();
          for (const n in nodes){
            myRange.insertNode(nodes[n]);
          }
        }
        this.definiceBtns[index].isActive = false;
        this.dialog = null;
      }
      this.onEditorInput();
      //this.cleanOutput();
    },

    onEditorButtonCreateElement(index, button) {
      console.log('[htmlEditor.onEditorButtonCreateElement] ', index, button )
      //button
      if (
          button.methods
          && typeof button.methods.dialog != "undefined"

      ) {
        try {
           //this[button.methods.dialog](index, button) //TODO Obsolete
            this[button.methods.name](index, button)
        } catch (e) {
          //console.log('[htmlEditor.onEditorButtonCreateElement] ERR',  e )
          this._buttonActionDefault(index, button)
        }

        //console.log('[htmlEditor.onEditorButtonCreateElement] IS method',  button.methods.dialog  )

        //console.log('[htmlEditor.onEditorButtonCreateElement] TYPE method',  typeof method )
      }else{
        //console.log('[htmlEditor.onEditorButtonCreateElement] not method',  button )
        //TODO custom insert method
        this._buttonActionDefault(index, button)
      }
    },

    callDialogMethod(method = null){

    },

    _buttonActionDefault(index, button) {
      console.log('[htmlEditor._buttonActionDefault]', index, button )
      //const myRange = document.getSelection().getRangeAt(0);

      this.elementData.tag = button.tagName;
      this.elementData.button = index;

      if(button.isWrap){
        const myRange = this.contentSelection.range
        const fragment = document.createElement('div');
        fragment.appendChild( myRange.cloneContents().cloneNode(true) );
        this.elementData.wrappedText = fragment.innerHTML
      }
      if(button.params){
        for (const i in button.params) {
          this.elementData.params[i] = button.params[i].value;
        }
      }else{
        this.elementData.params = {}
      }

      if(button.child){
        this.elementData.child = button.child
        /*
        for (const i in button.params) {
          this.elementData.params[i] = button.params[i].value;
        }
        */
      }else{
        this.elementData.child = null
      }


      this.elementData.params['data-cms-element-type'] = index;
      this.elementData.params['data-cms-element-id'] = this.generateRandomString();

      if(button.dialog){
        this._createDialog(button.dialog)
        //this.dialog = button.dialog;
        //this.dialog.display = true;
        //console.log('[htmlEditor._buttonActionDefault] je DIALOG', button.dialog )
      }else{
        this.dialog = null;
        //console.log('[htmlEditor._buttonActionDefault] NENI DIALOG' )
        //this._buttonActionDefaultCreate();
        this.callInsertMethod();
      }

    },

    _createDialog(dialogConfig) {

      if(typeof dialogConfig.config != "undefined") {

        let formData = new FormData();
        formData.append('subject', this.contentSelection.selection);

        axios.defaults.baseURL = this.getBaseRoot();
        axios({
          method: 'post',
          url: this.getApiConfig('wysiwyg.' + dialogConfig.config.pathIdent + '.path'),
          data: formData,
          withCredentials: true,
          headers: this.getRequestHeader(),
        }).then((res) => {
          if(typeof res.data.response.form.inputs != "undefined") {
            dialogConfig.inputs = res.data.response.form.inputs;
          }

          if(typeof dialogConfig.submitMethod != "undefined") {
            dialogConfig.submitMethod = dialogConfig.submitMethod;
          }
        }).catch(function(err){
          this.specialDialog.display = false;
          console.log("dev _createDialog catch", err)
        }).finally((data) =>{
          this.dialog = dialogConfig;
          this.dialog.submitMethod = dialogConfig.config.validationMethod;
          this.dialog.display = true;

        })

      }else{
        this.dialog = dialogConfig;
        this.dialog.display = true;
      }
    },

    buttonActionDynamicCreate() { //vytvori dynamicky element
      //console.log("dev buttonActionDynamicCreate", this.elementData.params);
      const formData = new FormData();
      formData.append('is_submit', true);
      formData.append('subject', this.contentSelection.selection);
      formData.append('data', JSON.stringify(this.elementData.params));

      const pathIdent = 'dev';

      axios.defaults.baseURL = this.getBaseRoot();
      axios({
        method: 'post',
        url: this.getApiConfig('wysiwyg.' + pathIdent + '.path'),
        data: formData,
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {

        const newElement = document.createElement(res.data.response.element.name);
        if(res.data.response.element.params) {
          for (const i in res.data.response.element.params ) {
            newElement.setAttribute(res.data.response.element.params[i].name, res.data.response.element.params[i].value)
          }
        }

        if(this.elementData.params) {
          for (const i in this.elementData.params ) {
            if (i.startsWith('data-cms-')) {
              newElement.setAttribute(i, this.elementData.params[i])
            }
          }
        }

        //todo data z formulare
        newElement.setAttribute('data-cms-element-form-inputs', JSON.stringify(this.elementData.params))

        if(this.elementData.wrappedText) {
          newElement.innerHTML = this.elementData.wrappedText
        }     else{
          newElement.innerHTML = 'dev jhtml';
        }

        if(this.contentSelection.range) {
          if( this.contentSelection.action == htmlEditorConst.action.create) {
            const myRange = this.contentSelection.range
            myRange.deleteContents();
            myRange.insertNode(newElement);

          } else if(this.contentSelection.action == htmlEditorConst.action.edit) {
            const myRange = new Range();
            myRange.selectNode(this.contentSelection.elementNode);
            myRange.deleteContents();
            myRange.insertNode(newElement);

          }else{
            console.log('[htmlEditor.buttonActionDynamicCreate] not action', this.contentSelection.action)
          }

          this.contentSelection.range = null;
        }


      }).catch(function(err){
        //this.specialDialog.display = false;
        console.log("dev buttonActionDynamicCreate  catch", err)
      }).finally((data) =>{
        //xx
        this.onEditorInput()
      })

    },

    _buttonActionDefaultCreate() {
      console.log('[htmlEditor._buttonActionDefaultCreate]', this.elementData)

      const newElement = document.createElement(this.elementData.tag);
      /*
      if(this.elementData.wrappedText) {
        newElement.innerHTML = this.elementData.wrappedText
      }
      */
      if(this.elementData.params) {
        for (const i in this.elementData.params) {
            newElement.setAttribute(i, this.elementData.params[i])
        }
      }

      if(
          typeof this.elementData.child != "undefined"
          && this.elementData.child
      ) {
        for (const i in this.elementData.child) {
          const newChild = document.createElement(this.elementData.child[i].tagName);

          if(typeof this.elementData.child[i].content != "undefined"){
            newChild.innerHTML = this.elementData.child[i].content
          }else{
            newChild.innerHTML = this.elementData.wrappedText
          }
          newElement.appendChild(newChild);
          //console.log('[htmlEditor._buttonActionDefaultCreate] POTOMEK', this.elementData.child[i]);

        }

      }else{
        console.log('[htmlEditor._buttonActionDefaultCreate] NEJSOU POTOMCI')
        if(this.elementData.wrappedText) {
          newElement.innerHTML = this.elementData.wrappedText
        }
      }

      //console.log('[htmlEditor._buttonActionDefaultCreate SELECTION]', this.contentSelection)

      if(this.contentSelection.range) {
        //console.log('[htmlEditor._buttonActionDefaultCreate] action', this.contentSelection.action)
        if( this.contentSelection.action == htmlEditorConst.action.create) {
          const myRange = this.contentSelection.range
          myRange.deleteContents();
          myRange.insertNode(newElement);

        } else if(this.contentSelection.action == htmlEditorConst.action.edit) {
          const myRange = new Range();
          myRange.selectNode(this.contentSelection.elementNode);
          myRange.deleteContents();
          myRange.insertNode(newElement);

        }else{
          console.log('[htmlEditor._buttonActionDefaultCreate] not action', this.contentSelection.action)
        }

        this.contentSelection.range = null;
      }

      //setup button
      //this.definiceBtns[this.elementData.button].isActive = true;

      this.onEditorInput()
      //console.log('[htmlEditor._buttonActionDefaultCreate]', this.elementData)
    },

    applyDialog(dialog) {
      //console.log('[htmlEditor.applyDialog INIT]', this.dialog)
      let err = {
        status: false,
      }

      for(const i in this.dialog.inputs){
        if(this.dialog.inputs[i].isMandatory == true) {
          if(this.elementData.params[i] == null || this.elementData.params[i] == ''){
            err.status = true;
            this.dialog.inputs[i].err = 'Vyplnte hodnotu';
          }else{
            this.dialog.inputs[i].err = null
          }
        }else{
          this.dialog.inputs[i].err = null
        }
      }

      if(!err.status) {
        this.dialog.display = false;
        if(
            typeof this.dialog.config != "undefined"
            && typeof this.dialog.config.createMethod != "undefined"
            && this.dialog.config.createMethod
        ) {
          this.callInsertMethod(this.dialog.config.createMethod);
        }else{
          this.callInsertMethod(null);
        }
      }
    },

    closeDialog(){
      this.dialog.display = false;
    },

    callInsertMethod(method = null) {
      console.log('[DEV htmlEditor.callInsertMethod]', method)

      const defaultMethod = '_buttonActionDefaultCreate';
      if(method == null) {
        method = defaultMethod;
      }
      //console.log('[htmlEditor.callInsertMethod]', method)

      try {
        this[method]()
      } catch (e) {
        this[defaultMethod]()
      }
    },

    updateTextSelection() {
      //console.log('[htmlEditor.updateTextSelection] ', )
      this.contentSelection.selection = window.getSelection();
      this.contentSelection.range = window.getSelection().getRangeAt(0);
      this.contentSelection.isCollapsed =  this.contentSelection.selection.isCollapsed;

      this.editElement(document.getSelection().anchorNode.parentNode) //anchorNode.parentNode
    },

    editElement(element){
      //console.log('[htmlEditor.editElement] INIT ', element)

      //vypnu pripadny predchazejici dialog
      this.contentSelection.elementNode =  null; //reset

      if(this.dialog) {
        this.dialog = null;
      }

      if(element != this.htmlEditor.ref) {
        this.setupActiveButton(element)
        let elType = this.getElementType(element)
        if(element.getAttribute('data-cms-element-type')) {
          elType = element.getAttribute('data-cms-element-type');
          if(typeof this.definiceBtns[elType] != "undefined") {
            this.definiceBtns[elType].isActive = true;
            this.setupActiveButtonFromObject(elType);
          }
        }

        if(typeof this.definiceBtns[elType] != "undefined") {
          //console.log('[htmlEditor.editElement] BTN', this.definiceBtns[elType].dialog.config.pathIdent)


          let myRange = document.createRange();
          myRange.selectNode(element);
          this.contentSelection.action = htmlEditorConst.action.edit;
          this.contentSelection.rangeEdited = myRange;

          if(this.definiceBtns[elType].dialog) {

            this.elementData.tag = this.definiceBtns[elType].tagName;
            this.elementData.button = elType;


            if(this.definiceBtns[elType].isWrap){
              this.elementData.wrappedText = element.innerHTML
            }
            //naplneni
            if(element.attributes) {
              for (let i = 0; i < element.attributes.length; i++) {
                this.elementData.params[element.attributes[i].name] = element.attributes[i].value
              }
              //dev
              if(typeof element.attributes['data-cms-element-form-inputs'] != "undefined") {
                if(element.getAttribute('data-cms-element-form-inputs')) {
                  this.elementData.params = JSON.parse(element.getAttribute('data-cms-element-form-inputs'));
                  //console.log('[htmlEditor.editElement] LOAD DATA', this.elementData.params)
                }
                //this.elementData.params.test_index = 'test dev input'
              }
            }
            //oprn dialog
            this.createItemDialog(this.definiceBtns[elType].dialog);


          }else{
            console.log('[htmlEditor.editElement] TODO general clear' )

          }


          /*
          let myRange = document.createRange();

          myRange.selectNode(element);
          myRange.setStart(element, 0);
          this.contentSelection.elementNode = myRange;
          */
          //special btns
          this.specialButton.eraseTag.isDisplayed = false;
        }else{
          this.specialButton.eraseTag.isDisplayed = true;
        }
        //console.log('[htmlEditor.editElement]', elType, element)
      }
    },

    createItemDialog(dialogConfig) {
      //console.log('[createItemDialog]', dialogConfig )
      if(
          typeof dialogConfig.config != "undefined"
          && dialogConfig.config.pathIdent != "undefined"
      ){
        this._createDialog(dialogConfig);
        this.dialog = dialogConfig;
      }else{
        this.dialog = dialogConfig;
      }
      this.dialog.display = true;
    },

    setupActiveButton(node){
     // console.log('[htmlEditor.selectExistingElement] ', node, this.definiceBtns)

      if(this.definiceBtns){
        for(const i in this.definiceBtns) {
          if(i == this.getElementType(node)) {
            this.definiceBtns[i].isActive = true;
          }else{
            this.definiceBtns[i].isActive = false;
          }
        }
      }

      console.log('[htmlEditor.setupActiveButton] ', this.getElementType(node), node)
      // vytvorit range
      this.contentSelection.elementNode =  node;

    },

    setupActiveButtonFromObject(btnIndex){
      if(this.definiceBtns){
        for(const i in this.definiceBtns) {
          if(i == btnIndex) {
            this.definiceBtns[i].isActive = true;
          }else{
            this.definiceBtns[i].isActive = false;
          }
        }
      }
    },

    getElementType(element) {
      let elementType = element.tagName;
      if(element.getAttribute('data-special-type')) {
        elementType = element.getAttribute('data-special-type')
      }
      return elementType.toLowerCase();
    },

    cleanOutput() {

      //TODO casovac
      console.log('[htmlEditor.cleanOutput] ')
    },

    clearFormat() {

      if(confirm("Opravdu chce odstranit formatovani?")) {
        const selection = window.getSelection();

        if(!selection.isCollapsed) {
          const myRange = selection.getRangeAt(0);
          const fragmentClean = document.createElement('span');

          fragmentClean.setAttribute('data-cms-special-delete', true)
          fragmentClean.setAttribute('class', 'deleted')
          fragmentClean.innerHTML = myRange.toString();

          myRange.deleteContents()
          myRange.insertNode(fragmentClean)

          console.log('[htmlEditor.clearFormat] myRange', myRange)
          this.cleanOutput();
        }else{
          this.htmlEditor.ref.innerHTML = this.htmlEditor.ref.innerText;
        }
        this.onEditorInput();
      }
    },

    switchEditor() {
      //console.log('[htmlEditor.switchEditor] ', this.specialButton.editorSwitchToCode.data.status)
      this.specialButton.editorSwitchToCode.data.status = !this.specialButton.editorSwitchToCode.data.status;

      if(this.specialButton.editorSwitchToCode.data.status === true) {
        //zobrazuje wysiwyg
        this.specialButton.editorSwitchToCode.isDisplayed = true;
        this.specialButton.editorSwitchToWYSIWYG.isDisplayed = false;
        this.htmlEditor.display = true;
      }else{
        //code
        this.specialButton.editorSwitchToCode.isDisplayed = false;
        this.specialButton.editorSwitchToWYSIWYG.isDisplayed = true;
        this.htmlEditor.display = false;
      }

    },

    generateRandomString() {
      const length = 20;
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      const charactersLength = characters.length;
      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    }
  },
  watch: {
    inputModel: {
      handler(newVal, oldVal) {

        if(newVal !== null){
          this.$emit('update:modelValue', newVal)
        }


        //console.log('[htmlEditor.watch.inputModel] ', newVal)
      },
      deep: true,
      immediate: true
    },
  },
}
</script>
<style scoped type="text/scss">

</style>
<template >
 [VersionControlInput]
  <!---->
  <InfiniteScrollList
      :custom-config="listConfig"
      ref="listWrapCompomenet"
  >
    <template #listRow="{itemData}">


      <version-control-tree-item
        :revert-value="revertItemValue"
        :level="itemData.diff"
        :parent-path="[]"
      />
      <display-debug>
        <template #debugView>revertItemValue {{revertItemValue}}</template>
      </display-debug>

      <!--
      <span
          @click.prevent="revertValue(itemData.diff)"
          class="btn btn-lg btn-primary">
        [dev]

      </span>

      <display-debug>
        <template #debugView>listConfig {{itemData}}</template>
      </display-debug>

-->
    </template>
  </InfiniteScrollList>

  <display-debug>
    <template #debugView>revertItemValue {{revertItemValue}}</template>
  </display-debug>

  <!--
    <display-debug>
      <template #debugView>listConfig {{listConfig}}</template>
    </display-debug>

    <display-debug>
      <template #debugView>inputObj {{inputObj}}</template>
    </display-debug>
     -->

</template>

<script>

import axios from "axios";
import InfiniteScrollList from '@/components/content/InfiniteScrollList'
import VersionControlTreeItem from "./version-control/VersionControlTreeItem"
export default {
  name: "VersionControlInput",
  components: {
    InfiniteScrollList,
    VersionControlTreeItem,
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  data() {
    return {
      inputModel: null,
      timer: null,
      timer_delay: 500,
      is_locked: true,
      revertItemValue: {},
      // this.listConfig.action.path = '/class/wiki/version-list?v=default&page='
      listConfig: {
          "dev": "config1",
          "type": "table",
          "template_type": "obsah",
          "wrap_class_name": "dashboard-list-wrap",
          "is_next_btn": true,
          "is_end_notification": true,
          "is_auto_refresh": true,
          "action": {
            "path": '/class/wiki/version-list?v=default&zaznam_id=' + this.inputObj.val + '&page=',
            "method": "get"
          },
          "preload": {
            "status": false,
            "interval_seconds": 60
          },
          "cols": {
            "data": "seznam",
            "uid": "id",
            "sort": {
              "name": "id",
              "order": "desc"
            }
          },
          "is_preload": true,
          "list": {
            "auto_refresh": {
              "status": true,
              "time": 15000
            },
            "is_next_btn": true,
            "is_end_notification": true
          }

      },
    }
  },
  mounted() {
    this.listConfig.action.path = '/class/wiki/version-list?v=default&zaznam_id=' + this.inputObj.val + '&page='
  },
  methods: {
        revertValue(value) {
          console.log('dev.revertValue', value)
        }
  },
  watch: {
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },

  },
};
</script>
<template >
  [PartsVersionControlInput]

  <display-debug>
    <template #debugView>part modelValue {{modelValue}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>part versions {{versions}}</template>
  </display-debug>

  <!--
<div v-if="versions">
  <div v-for="version in versions">

    <span>autor: {{version.autor}}</span>


    <span
        v-format-date:custom.time.timeOnly="version.datum"
        class="ms-2"
    >
      datum: {{version.datum}}
    </span>



    <span>
      displayChanges: {{displayChanges(version.value)}}
    </span>


    <span
      @click.prevent="revertValue(version.value)"
      class="btn btn-sm btn-outline-primary ms-2"
    >
      vratit hodnotu <i class="bi bi-arrow-return-left"></i>
    </span>





  </div>


  <div v-if="isChanged">
    <span
        @click.prevent="revertOriginal()"
        class="btn btn-sm btn-outline-primary ms-2"
    >
      <i class="bi bi-arrow-repeat"></i>
      obnovit
    </span>

  </div>


</div>
  -->

  <!--


  v-format-date="cell"

  <display-debug>

    <template #debugView>version: {{typeof version}}</template>
  </display-debug>

  <display-debug>

    <template #debugView>version: {{version}}</template>
  </display-debug>
[VersionControl]


<span @click.prevent="devRevert" class="btn btn-danger">
  set VC revert
</span>



<display-debug>
  <template #debugView>VC modelValue: {{modelValue}}</template>
</display-debug>

<display-debug>
  <template #debugView>VC modelValueOriginal: {{modelValueOriginal}}</template>
</display-debug>


  -->


</template>

<script>

export default {
  name: "PartsVersionControlInput",
  components: {

  },
  props: {
    "versions": [Object, Array],
    "modelValue": [Object, Array, String, Number],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isChanged: false,
      modelValueOriginal: null,
    }
  },
  mounted() {
    this.modelValueOriginal = this.modelValue;
  },
  methods: {
    displayChanges(values) {
      let display;
      if(values){
        if(typeof this.modelValueOriginal == "object" ){
          display = {}
          for(const i in this.modelValueOriginal) {
            //revertValue[i] = value[i].from
            console.log('dev displayChanges',values[i])
            display[i] = "[" + values[i].type + "] z: '" + values[i].from + "'  na: '" + values[i].to + "'"
          }
        }else{
          console.log('dev displayChanges',values)
          display = "[" + values.type + "] z: '" + values.from + "' na: '" + values.to + "'"
          //revertValue = value.from
        }


      }
      return display
      console.log('dev.displayChanges', values);
    },
    revertOriginal() {
      this.$emit('update:modelValue', this.modelValueOriginal )
    },
    revertValue(value) {
      this.isChanged = true;
      let revertValue;
      if(typeof this.modelValueOriginal == "object" ){
        revertValue = {}
        for(const i in this.modelValueOriginal) {
          revertValue[i] = value[i].from
        }
      }else{
        revertValue = value.from
      }
      this.$emit('update:modelValue', revertValue )
    },
  },
  watch: {
    /*
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
*/
  },
};
</script>
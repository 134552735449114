<template>
  <div class="cms5-login-wrap d-flex flex-wrap justify-content-center align-items-center p-2 w-100 h-100 g-3" style="width: 100%; ">
    <div
      class="d-md-flex flex-grow-1 px-4 px-md-0"
    >
      <div
       class="cms5-login-column  flex-md-fill"
      >

        <div class="text-center">
          <i class=" mt-4 bi bi-box-seam-fill text-secondary" style="font-size: 70px;"></i>
        </div>

        <h1 class="display-6 fw-bold lh-1 mb-2 text-center text-secondary">Vítejte v administaci webu j5.cz</h1>
        <p class="col-lg-10 ">
          Nejdřív se přihlaste, pokud nemáte účet kontaktuje správce s příslušným oprávněním.
        </p>
        <p class="col-lg-10 d-none">
          Zapomenute heslo si vyresetuje <span @click.prevent="activateReset()">zde</span>
        </p>
      </div>
      <div
          class="cms5-login-column  flex-md-fill"
      >
        <LoginForm
            :is_password_reset="is_password_reset"
            :return-path="returnPath"
        />
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from "@/modules/LoginForm";

import axios from "axios";
export default {
  name: 'ContentView',
  components: {
    LoginForm
  },
  data() {
    return {
      returnPath: null,
      is_password_reset: false
    }
  },
  methods: {
    activateReset(){
      console.log("activateReset");
      this.is_password_reset = true;
    },
  },
  watch: {
    reloadSignal: {
      handler() {
        console.log('dev view reloadSignal')

      },
      immediate: true
    },
    '$route' (to, from) {

    }
  },
  created() {

  },
  mounted() {
    if (typeof this.$route.query.path != "undefined") {
      this.returnPath = this.$route.query.path
    }

  },
}
</script>

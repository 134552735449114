<template >

  <div
      v-if="versions && versions.length > 0"
      class="mt-2"
  >
    <div class="dropdown d-flex">
      <span
          @click.prevent="isOpen = !isOpen"
          class="btn btn-link btn-sm text-danger text-decoration-none  dropdown-toggle"
      >
        <i class="bi bi-clock me-2"></i>
        Verze
      </span>
      <span
          v-if="isOpen && isChanged"
          class="ms-auto btn btn-sm btn-outline-danger"
          @click.prevent="revertOriginal()"
      >
          <i class="bi bi-arrow-repeat"></i> Obnoveni
      </span>
    </div>
    <div
        v-if="isOpen"
        class="list-group"
    >
      <div
          v-for="version in versions"
          class="list-group-item"
      >
        <div class="d-flex ">
          <span class="fs-6 text-secondary">
            <small
                v-format-date.onlyTime="version.datum"
                class="ms-1"
            ></small>
            <small
                class="ms-1"
            >|</small>
            <small
                v-format-date:custom.time.timeOnly="version.datum"
                class="ms-1"
            ></small>
          </span>
          <small class="ms-auto" title="Autor">
            <i class="bi bi-person me-1"></i>
            {{version.autor}}
          </small>
        </div>

        <div>


          <!--
          <pre>typeof: {{typeof version.value}}</pre>
<pre>typeof: {{version.type}}</pre>
          -->
          <small
              v-for="item in displayChanges(version.value)"
              class="text-danger d-block"

          >
            <span v-html="item"></span>
          </small>
        </div>

        <div class="d-flex">
                  <span
                      @click.prevent="isChanged = true; revertValue(version.value)"
                      class="btn btn-sm btn-outline-danger ms-auto"
                  >
                      Vratit hodnotu <i class="bi bi-arrow-return-left"></i>
                  </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "VersionControlInput",
  components: {

  },
  props: {
    "versions": [Object, Array],
    "modelValue": [Object, Array, String, Number],
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isOpen: false,
      isChanged: false,
      modelValueOriginal: null,
    }
  },
  mounted() {
    this.modelValueOriginal = this.modelValue;
  },
  methods: {
    displayChanges(values) {
      let display =[];
      if(values){
        if(typeof this.modelValueOriginal == "object" ){
          display = {}
          for(const i in this.modelValueOriginal) {
            if(values[i].type == 'change') {
              display[i] = "Změna ':<i>" + values[i].from + "</i>' <i class='bi bi-arrow-right-short'></i>: '<i>" + values[i].to + "</i>'"
            }else{
              display[i] = "[" + values[i].type + "] : '" + values[i].from + "' <i class='bi bi-arrow-right-short'></i>: '" + values[i].to + "'"
            }


          }
        }else{
          if(values.type == 'change') {
            display.push("Změna: '" + values.from + "' <i class='bi bi-arrow-right-short'></i> '" + values.to + "'")

          }else{
            display.push("[simple-" + values.type + "]  '" + values.from + "' <i class='bi bi-arrow-right-short'></i> '" + values.to + "'")

          }
          //display = "[" + values.type + "] z: '" + values.from + "' na: '" + values.to + "'"

          //revertValue = value.from
        }


      }
      return display;
      console.log('dev.displayChanges', values);
    },
    revertOriginal() {
      this.$emit('update:modelValue', this.modelValueOriginal )
    },
    revertValue(value) {
      this.isChanged = true;
      let revertValue;
      if(typeof this.modelValueOriginal == "object" ){
        revertValue = {}
        for(const i in this.modelValueOriginal) {
          revertValue[i] = value[i].from
        }
      }else{
        revertValue = value.from
      }
      this.$emit('update:modelValue', revertValue )
    },
  },
  watch: {
    /*
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
*/
  },
};
</script>
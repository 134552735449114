<template >
   <div v-if="level">
     <div v-for="(item, index) in level">

       <div
           v-if=" typeof item.type == 'undefined'"
           class="bg-warning ms-4"
       >
           [next level - {{index}}]
           <version-control-tree-item
               :revert-value="revertValue"
               :level="item"
               :parentPath="parentPath"
           />
       </div>
       <div v-else>

      <span
          @click.prevent="$emit('revertValue', 6666)"
          class="btn btn-sm btn-primary">
          retvert value

      </span>

         <display-debug>
           <template #debugView>item: {{item}}</template>
         </display-debug>

       </div>
     </div>
   </div>
</template>

<script>
import VersionControlTreeItem from "./VersionControlTreeItem";
export default {
  name: "VersionControlTreeItem",
  components: {
    VersionControlTreeItem
  },
  props: {
    "level": [Object, Array],
    "parentPath": [Object, Array],
    "revertValue": [Object, Array],
  },
  emits: ['update:revertValue'],
  data() {
    return {
      inputModel: null,
    }
  },
  mounted() {

  },
  methods: {
        xrevertValue(value) {
          console.log('dev.revertValue', value)
        }
  },
  watch: {
    /*
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
*/
  },
};
</script>
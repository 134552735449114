<template>

  <!--
  <pre>action {{action}}</pre>
  <pre>formData {{formData}}</pre>
  <div v-highlight>dev plugin</div>
  <pre>{{err}}</pre>
-->

  <div
      v-if="forms.login.display"
  >
    <form
        @submit.prevent="submitLoginForm"
        class="p-4 p-md-5 border rounded-3 bg-light"
    >

      <div
          v-if="!forms.login.status && forms.login.error"
          class="text-danger mb-2"
      >
        <i class="bi bi-exclamation-triangle me-2"></i> {{forms.login.error}}
      </div>

      <div>
        <label for="login3" class="form-label">
          <i class="bi bi-person"></i>
          Login
        </label>
        <input
            id="login3"
            v-model="formData.login"
            type="text"
            required
            class="form-control mb-2"
            placeholder="zadejte vas login nebo email"
        />
      </div>
      <div>
        <label for="heslo" class="form-label">
          <i class="bi bi-key"></i>
          Heslo
        </label>
        <input
            id="heslo"
            v-model="formData.password"
            type="password"
            placeholder="heslo"
            class="form-control"
            required
        />
      </div>

      <div>
        <button
            type="submit"
            :disabled="(formData.login && formData.password)? false: true"
            class="btn btn-primary mt-2 w-100"
        >
          Přihlásit se
        </button>
      </div>

    </form>

    <div
    class="w-100 text-end"
    >
      <button
          @click.prevent="switchFormDisplay('reset_password')"
          class="btn btn-sm btn-link"
      >
        Zapomenuté heslo
      </button>
    </div>

  </div>



  <div
      v-if="forms.reset_password.display"
  >
    <div
        v-if="forms.reset_password.status === false && forms.reset_password.error"
        class="text-danger"
    >
      <i class="bi bi-exclamation-triangle me-2"></i> {{forms.reset_password.error}}
    </div>
    <div
      v-else-if="forms.reset_password.status === true"
    >
      Do vašeho emailu za chvíli dorazí zpráva s návodem k změně hesla.<br/>
      Pokud jí tam během pár minut nenajdete, podívejste se do složky s Nevyžadanou poštou.

    </div>

    <form
        v-if="forms.reset_password.status !== true"
        @submit.prevent="submitResetForm"
        class="p-4 p-md-5 border rounded-3 bg-light"
    >

      <div>
        <label for="login2" class="form-label">
          <i class="bi bi-person"></i>
          Login
        </label>
        <input
            id="login2"
            v-model="formData.login"
            type="text"
            required
            class="form-control mb-2"
            placeholder="zadejte vas login nebo email"
        />
      </div>

      <div>
        <button
            type="submit"
            :disabled="(formData.login && formData.password)? false: true"
            class="btn btn-primary mt-2 w-100"
        >
          Odeslat žádost o změnu hesla
        </button>
      </div>

    </form>
    <div
        class="w-100 text-end"
    >
      <button
          @click.prevent="switchFormDisplay('login')"
          class="btn btn-sm btn-link"
      >
        Přihlásit se
      </button>
    </div>

  </div>


<!--
  <display-debug>
    <template #debugView>returnPath {{returnPath}}</template>
  </display-debug>
  <pre>forms:{{forms}}</pre>
  -->
</template>

<script>
import axios from "axios";
import general from "@/components/config/general";
export default {
  name: "LoginForm",
  props: {
    "is_password_reset": Boolean,
    "returnPath": {
      type: [String],
      required: false,
      default: null
    }
  },
  data() {
    return {
      forms: {
        login: {
          display: false,
          status: null,
          error: null,
        },
        reset_password: {
          display: false,
          status: null,
          error: null,
        },
      },
      formData: {
        "login": null,
        "password": null,
      },
    }
  },
  methods: {
    switchFormDisplay(ident){
      for(const key in this.forms) {
        if(key == ident){
          this.forms[key].display = true;
        }else{
          this.forms[key].display = false;
        }
        this.forms[key].error = null;
        this.forms[key].status = null;
      }
    },

    async submitResetForm(){
      const formData = new FormData;
      //formData.append(this.cfg.api.user.create_reset_request.input.login, this.formData.login)
      formData.append(this.getApiConfig('user.create_reset_request.input.login'), this.formData.login)


      axios.defaults.baseURL = this.getBaseRoot();
      await axios({
        //url: this.cfg.api.user.create_reset_request.path,
        //method: this.cfg.api.user.create_reset_request.method,
        url: this.getApiConfig('user.create_reset_request.path'),
        method: this.getApiConfig('user.create_reset_request.method'),


        data: formData,
      }).then((res) => {
        console.log("dev create_reset_request", res.data.response )
        //this.forms.reset_password.error = 'testovaci error';
        this.forms.reset_password.status = true;

        /*
              if(res.data.response.error) {
                //this.is_password_changed = false;
                //this.error = res.data.response.error;
              }else{
                //this.is_password_changed = true;
              }

         */
      });




    },

    async submitLoginForm(){
      this.forms.login.error = null;
      this.forms.login.status = null;

      if (this.formData.login && this.formData.password) {
        let data = new FormData()
        data.append('login', this.formData.login)
        data.append('password', this.formData.password);

        axios.defaults.baseURL = this.getBaseRoot();
        axios.post(
            this.getApiConfig('user.login.path'),
            //this.cfg.api.user.login_path,
            data,
            {
              headers: {}
        }).then((res) => {
          if(res.data.status) {
            this.err = false;
            localStorage.setItem(general.login_local_storage_name, res.data.response.user.data.hash)
          }else{
            this.forms.login.error = res.data.err.message;
            this.forms.login.status = false;
          }
        }).catch((err) => {
          this.forms.login.error = 'Chyba systému, kontaktujte správce';
          this.forms.login.status = false;

        }).finally(() => {
          if (this.forms.login.status !== false) {
            if(this.returnPath) {
              this.$router.push(this.returnPath)
            }else{
              this.$router.push('/dashboard')
            }

          }

        })
      }else{
        this.forms.login.error = 'Nejsou vyplneny vsechny udaje';
        this.forms.login.status = false;
      }
    },

    //------------------------------------

    async createResetRequest() {
      //create_reset_request

      const formData = new FormData;
      //formData.append(this.cfg.api.user.create_reset_request.input.login, this.formData.login)
      formData.append(this.getApiConfig('user.create_reset_request.input.login'), this.formData.login)

      axios.defaults.baseURL = this.getBaseRoot();
      await axios({
        //url: this.cfg.api.user.create_reset_request.path,
        //method: this.cfg.api.user.create_reset_request.method,
        url: this.getApiConfig('user.create_reset_request.path'),
        method: this.getApiConfig('user.create_reset_request.method'),

        data: formData,
      }).then((res) => {
        console.log("dev create_reset_request", res.data.response )
  /*
        if(res.data.response.error) {
          //this.is_password_changed = false;
          //this.error = res.data.response.error;
        }else{
          //this.is_password_changed = true;
        }

   */
      });




      console.log("DEV createResetRequest", this.formData);
      this.action = 'reset_send'

    },

/*
    switchAction(action){
      this.action = action;
    },
*/
    reloadParent(val) {
      this.$emit("update:reloadSignal", val);
    }

  },
  watch: {
    /*
    reset_password(val) {
      console.log("dev reset_password", val)
    },
    reloadSignalBox: {
      handler(val) {
        this.reloadParent(val);
      },
      immediate: true
    },
    reloadSignalList: {
      handler(val) {
        this.reloadParent(val);
      },
      immediate: true
    },
    */
  },
  created() {
    this.forms.login.display = true;
  },
  mounted() {
    if (process.env.NODE_ENV === 'development') {
      this.formData = {
        login: this.getConfig('dev.login'),
        password: this.getConfig('dev.pass'),
      }
    }
  },

};
</script>
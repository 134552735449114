<template>
</template>
<script>

import axios from "axios";
import general from "@/components/config/general";
//import configApi from "@/components/config/api";
//import configGeneral from "@/components/config/general";
export default {
  name: "LoginLogic",
  props: {
    "user": Object,
  },
  components: [
      axios
  ],
  data() {
    return {
      loginStatus: true,
      i: 0,
      userInner: {},
    }
  },
  emits: [
    'update:user'
  ],
  methods: {
    async checkLogin(){

      if(this.loginStatus) {
        try {
          axios.defaults.baseURL = this.getBaseRoot();
          await axios({
            url: this.getApiConfig('user.login_check.path') ,
            method: this.getApiConfig('user.login_check.method'),
            withCredentials: true,
            headers: this.getRequestHeader(),
          }).then((res) => {

            if(res.data.status) {
              this.loginStatus = true;
              this.userInner = res.data.response.user;

              let langCode;
              if(res.data.response.user.lang_code){
                langCode = res.data.response.user.lang_code;
              }else{
                langCode = general.lang_id_default_value;
              }

              localStorage.setItem(
                  general.lang_id_local_storage_name,
                  langCode
              );

            }else{
              this.loginStatus = false;
              this.$router.push('/?path=' + this.$route.path)
            }
          });
        } catch (err) {
          this.loginStatus = false;
          console.log('no login path', this.$route.path)
          this.$router.push('/?err=1')
        }
        this.$emit("update:user", this.userInner);
      }

      this.i++;
      //console.log('dev checkLogin', this.i, apiPath)  ;
    },
  },
  created() {
    //console.log('dev checkLogin', this.cfg.general.autologin_delay)  ;
    //this.addFlash("testovaci", "tohle je testovaci flash zprava")
    //this.addFlash("testovaci2", "tohle je testovaci DEUHA flash zprava")
    this.checkLogin();
    //setInterval(() => this.checkLogin(), this.cfg.general.autologin_delay);
    setInterval(() => this.checkLogin(), this.getConfig('autologin_delay'));
  }
};
</script>
<template>
  <!--
  <display-debug>
    <template #debugView>config {{config}}</template>
  </display-debug>
-->
  <div class="cms5-infinite-scroll-list-wrap" xstyle="visibility: hidden;">
    <!--  -->
    <header class="cms5-infinite-scroll-list-header d-flex align-items-center me-1">
      <span
        v-if="listData.title"
        class="title flex-fill fw-lighter fs-4 text-primary"
      >
        {{listData.title}}
      </span>

      <span class="ms-auto reload-wrap">
        <span
            @click.prevent="reloadList()"
            class="btn btn-primary"
        >
          <i class="bi bi-repeat me-1"></i>
          <!-- reload -->
        </span>
      </span>
    </header>

    <!--
    <div class="reload-wrap me-auto">


    </div>
-->

    <div
        v-if="listData.preloadList.length"
        class="preload-wrap"
    >
      <span
          @click.prevent="copyPreloadToList()"
          class="btn btn-primary badge badge-pill badge-danger"
      >
        {{ listData.preloadList.length }} polozek
      </span>
    </div>

    <div
        ref="listWrap"
        :class="'cms5-infinite-scroll-list-content' + (config.wrap_class_name? (' ' + config.wrap_class_name): '  no-custom')"
    >
      <div
          ref="listContent"
      >
        <table
            v-if="config.type =='table' && listData.list && listData.list.length > 0"
            class="table table-striped table-responsive"
        >

          <slot name="tableHead"></slot>
          <tbody>
            <tr
             v-for="(item) in listData.list"
             :ref="'listItemId' + item[config.cols.uid]"
             class=""
         >
           <slot name="listRow" :itemData="item">
             <td>
               <display-debug>
                 <template #debugView>
                   [row] item-type {{config.template_type}},
                   item {{item}}
                 </template>
               </display-debug>
             </td>
           </slot>
         </tr>
          </tbody>
        </table>


        <div
            v-else-if="listData.list && listData.list.length > 0"
            class="list-group"
        >
          <div
             v-for="(item) in listData.list"
             :ref="'listItemId' + item[config.cols.uid]"
              class="list-group-item"
          >
            <slot name="listItem" :itemData="item">
              <div
                  @click.prevent="actionRowClick(item)"

              >
                <display-debug
                    v-once
                >
                  <template #debugView>
                    [item] item-type {{config.template_type}},
                    item {{item}}
                  </template>
                </display-debug>
              </div>

            </slot>
          </div>


        </div>
        <div
            v-else
            ref="listEmpty"
            class="empty-list-wrap"
        >
          <slot name="emptyListSlot">
            <div class="empty-list">
              <div class="bi bi-wifi-off"></div>
              <div>
                Zadne data [TODOx]
              </div>
            </div>

          </slot>

        </div>

        <div
            v-if="!listData.isEnd"
            class="cms5-infinite-scroll-navigation"
        >
          <div
              v-if="display.loading"
              class="list-loading-wrap"
          >
            <div class="spinner-border text-secondary"></div>
            <span class="ms-2 text-secondary">Nahrávám další data</span>
          </div>

          <div
              v-if="display.detector"
              ref="nextDetector"
              class="cms5-infinite-scroll-detector"
          ></div>
          <div class="cms5-infinite-scroll-btn-wrap">
            <div
                @click.prevent="handlerClickNextPage()"
                class="btn btn-primary"
            >
              <i class="bi bi-box-arrow-in-down"></i> Načíst pokračování
            </div>
          </div>
        </div>

        <div
            v-else
            class="end-wrap mb-5"
        >
          <slot name="endListSlot">
            <i class="bi bi-ban text-secondary"></i>
            <p class="text text-secondary"><em>Jste na konci, nic dalšího tady už nenajdete</em></p>
          </slot>
        </div>
      </div>
    </div>
  </div>


  <!--
    <display-debug>
      <template #debugView>
        config {{config}}
      </template>
    </display-debug>
    <display-debug>
      <template #debugView>
        listData.list: {{listData.list}}
      </template>
    </display-debug>


  -->


</template>

<script>
//let timer;

import axios from "axios";
let timerVisibleItem;
let timerPreload;
//let timerScrollDelay;
//let timerAutoRefresh;
const config = {
  dev: 'config1',
  type: 'list', //list, table
  template_type: 'general',
  wrap_class_name: 'testovaci-infinite-scroll',
  is_next_btn: true,
  is_end_notification: true,
  is_auto_refresh: true,
  //is_preload: false,
  action: {
    path: null,
    method: null,
  },
  preload: {
    status: true,
    interval_seconds: 60
  },
  cols: {
    data: 'data',
    uid: 'id',
    sort: {
      name: 'id',
      order: 'asc'
    }
  }
};
const types = {
  "firstLoad": "firstLoad",
  "preload": "preload",
  "standart": "standart",
  "scroll": "scroll",
}

export default {
  name: "InfiniteScrollList",
  props: {
    "customConfig": {
      type: [Array,Object],
      required: true,
    },
    "defaultData": {
      type: [Array,Object],
      required: false,
    },
    "keyModul": {

      required: false,
    },
  },
  components: {

  },
  data() {
    return {
      action: {},
      config: {},
      loadedData: {},


      display: {
        "loading": false,
        "detector": true,
        "end": false,
      },
      listData: {
        "title": null,
        "page": 1,
        "page_preload": 1,
        "countComplete": null,
        "list": [],
        "preloadList": [],
        "isEnd": false,
        "isScrollMove": false,
        "scrollTarget": null,
        "heights": {
          wrap: null,
          list: null,
        },
        rowActions: {},
      },
    }
  },
  created() {
    //console.log('DEVX dev.InfiniteScrollList ------------------------------------------------------------------------');
    //console.log('DEVX dev.InfiniteScrollList INFSC',this.keyModul, this.customConfig.action);

  },
  mounted() {
    this.config = this.superMerge(
        config,
        this.customConfig
    );
    this.action = this.config.action;
    //console.log('dev.InfiniteScrollList config', this.config );


    this.$refs.listWrap.addEventListener('scroll', () => {
      this.handlerScroll();
      this.checkIsItemVisible()
    });

    //auto load
    this.firstLoadData();

    //checkPreload
    this.checkPreloaded();
  },

  beforeUnmount() {
    this.$refs.listWrap.removeEventListener('scroll', this.handlerScroll());
  },

  methods: {
    checkPreloaded(){
      if(this.config.preload.status){
        if(timerPreload) clearInterval(timerPreload);
        timerPreload = setTimeout(
            () => {
              this.listData.page = 1;
              this.loadData(types.preload);
            },
            this.config.preload.interval_seconds * 1000
        )
      }
    },

    reloadList(){
      this.listData.list = [];
      this.listData.isEnd = false;
      this.listData.page = 1;
      this.display.detector = true;
      this.display = {
        "loading": false,
        "detector": true,
        "end": false,
      };

      this.firstLoadData();
    },

    handlerScroll(){
      const elementList =  this.$refs['listWrap']; //
      const elementDetector = this.$refs['nextDetector'];

      if(
          this.isVisibleElelement(
              elementList,
              elementDetector
          )
      ) {
        this.display.detector = false;
        this.loadData(types.scroll)
      }
    },

    handlerClickNextPage(){
      this.loadData(types.standart);
    },

    firstLoadData() {
      this.loadData(types.firstLoad);
    },

    async loadData(type) {
      this.display.loading = true;
      let cacheData = null;
      //todo

      let page = this.listData.page;
      if(type == types.preload) {
        page = this.listData.page_preload;
      }

      axios.defaults.baseURL = this.getBaseRoot();
      axios({
        url: this.config.action.path + page,
        method: this.config.action.method,
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        cacheData = res.data.response;
        this.listData.title = cacheData.title;
        //row actions
        this.listData.rowActions = res.data.response.rowActions;

      }).catch((err)=> {
        console.log('dev.InfiniteScrollList loadData ERR', err)
      }).finally(()=> {
        this.manageData(type, cacheData)
        this.display.loading = false;
      })

    },

    manageData(type, data){
      if(type == types.standart) {
        this.manageDataStandart(data);
      }else if(type == types.firstLoad) {
        this.manageDataFirstLoad(data);
      }else if(type == types.scroll) {
        this.manageDataScroll(data);
      }else if(type == types.preload) {
        this.manageDataPreload(data);
      }


    },

    manageDataFirstLoad(data){
      //console.log('dev.InfiniteScrollList manageDataFirstLoad', data, this.config);
      //TODO prepsat nazev promene
      if(data[this.config.cols.data] && data[this.config.cols.data].length > 0) {
        for(const i in data[this.config.cols.data] ){
          this.addToList(data[this.config.cols.data][i])
        }
        this.sortList();

        setTimeout(() => {
          if(!this.checkOverflowHeights() && !this.listData.isEnd) {

            if(typeof data.nextPage != "undefined" && data.nextPage){
              this.listData.page = data.nextPage;
              //console.log('dev.InfiniteScrollList manageDataFirstLoad page', this.listData.page);
              this.firstLoadData();
            }else{
              this.listData.isEnd = true;
            }

            //console.log('dev.InfiniteScrollList manageDataFirstLoad heights call firstLoadData', this.listData.page);
            //console.log('dev.InfiniteScrollList manageDataFirstLoad heights [dopln seznam]');
          }else{
            this.listData.page = data.nextPage;
          }

        }, 1);
      }
    },

    manageDataStandart(data){
      //console.log('dev.InfiniteScrollList manageDataStandart', data, this.config);
      if(data[this.config.cols.data] && data[this.config.cols.data].length > 0) {
        for(const i in data[this.config.cols.data] ){
          this.addToList(data[this.config.cols.data][i])
        }
        this.sortList()

        if(typeof data.nextPage != "undefined" && data.nextPage){
          this.listData.page = data.nextPage;
          //console.log('dev.InfiniteScrollList manageDataStandart page', this.listData.page);
        }else{
          this.listData.isEnd = true;
        }

      }
    },

    manageDataScroll(data){
      //console.log('dev.InfiniteScrollList manageDataScroll', data);
      //TODO prepsat nazev promene
      if(data[this.config.cols.data] && data[this.config.cols.data].length > 0) {
        for(const i in data[this.config.cols.data] ){
          this.addToList(data[this.config.cols.data][i])
        }

        this.sortList();

        setTimeout(() => {
          if(typeof data.nextPage != "undefined" && data.nextPage){
            this.listData.page = data.nextPage;
            //console.log('dev.InfiniteScrollList manageDataScroll page', this.listData.page);
            this.display.detector = true;
          }else{
            this.listData.isEnd = true;
          }

        }, 100);
      }
      //this.display.detector = true;
    },

    manageDataPreload(data){
      //console.log('dev.InfiniteScrollList manageDataPreload', data);
      if(data[this.config.cols.data] && data[this.config.cols.data].length > 0) {
        for(const i in data[this.config.cols.data] ){
          this.addToPreloadList(data[this.config.cols.data][i])
        }

        if(typeof data.nextPage != "undefined" && data.nextPage){
          this.listData.page_preload = data.nextPage;
          this.loadData(types.preload);
        }
      }

    },

    addToList(item){
      //console.log('dev.InfiniteScrollList addToList', item, this.config.cols.uid);
      if(!this.listData.list.some(data => data[this.config.cols.uid] === item[this.config.cols.uid])) {
        this.listData.list.push(item);
      }
    },

    addToPreloadList(item){
      //console.log('dev.InfiniteScrollList addToList', item, this.config.cols.uid);
      if(
          !this.listData.preloadList.some(data => data[this.config.cols.uid] === item[this.config.cols.uid])
          && !this.listData.list.some(data => data[this.config.cols.uid] === item[this.config.cols.uid])
      ) {
        this.listData.preloadList.push(item);
      }
    },

    sortList() {
      const orderName = this.config.cols.sort.name
      //const orderName = 'flash_id'
      //this.config.cols.sort.order = 'asc'

      //console.log('dev.InfiniteScrollList sortList', orderName, this.config.cols.sort.order)
      if(this.config.cols.sort.order == 'desc'){
        this.listData.list.sort((a, b) => b[orderName] - a[orderName] );
      }else{
        //asc
        this.listData.list.sort((a, b) => a[orderName] - b[orderName] );
      }
    },

    checkOverflowHeights(){
      //console.log('dev.InfiniteScrollList manageDataFirstLoad heights [FCE]', this.$refs);

      try {
        //wrap
        const elementWrap = this.$refs['listWrap'].getBoundingClientRect();
        this.listData.heights.wrap = elementWrap.height;
        //list
        const elementList = this.$refs['listContent'].getBoundingClientRect();
        this.listData.heights.list = elementList.height;
/*
        console.log(
            'dev.InfiniteScrollList manageDataFirstLoad heights LIST:WRAP',
            this.listData.heights.list < this.listData.heights.wrap,
            this.listData.heights.list,
            this.listData.heights.wrap
        );
*/
        if(this.listData.heights.list < this.listData.heights.wrap) {
          return false;
        }else{
          return true;
        }

      }catch (e) {
        console.log('dev.InfiniteScrollList  checkHeights ERR',  e);
      }



    },

    isVisibleElelement(parent,element) {

      //let parent = this.$refs[parentElement];
      //let element = this.$refs[elementRef];



      const rectParent = parent.getBoundingClientRect();

      if(element){
        if (typeof element.length != "undefined" ){
          if (element.length > 0) {
            element = element[0];
          }else {
            return false;
          }
        }
        const rect = element.getBoundingClientRect();
        return (
            rect.top >= rectParent.top
            // && rect.left >= rectParent.left
            && rect.bottom <= rectParent.bottom
            // && rect.right <= rectParent.right
        );
      }else{
        return false
      }
    },

    actionRowClick(item) {
      if(
          typeof this.listData.rowActions != "undefined"
          && typeof this.listData.rowActions.click != "undefined"
          && typeof this.listData.rowActions.click.utility != "undefined"
          && typeof this.listData.rowActions.click.action != "undefined"
      ){
        const res = this.runAdhocFunction(
            this.listData.rowActions.click.utility,
            this.listData.rowActions.click.action,
            item
        );
      }

    },

    checkIsItemVisible(){


      if(
          typeof this.listData.rowActions != "undefined"
          && typeof this.listData.rowActions.visible != "undefined"
          &&typeof this.listData.rowActions.visible.utility != "undefined"
          && typeof this.listData.rowActions.visible.action != "undefined"
      ){
        if(timerVisibleItem) clearInterval(timerVisibleItem);
        timerVisibleItem = setTimeout(
            () => {
              try {
                const elementList =  this.$refs['listWrap']; //
                for (const i in this.listData.list){
                  const itemRefName = 'listItemId' + this.listData.list[i][this.config.cols.uid];
                  if(
                      this.isVisibleElelement(
                          elementList,
                          this.$refs[itemRefName]
                      )
                  ){

                    const res = this.runAdhocFunction(
                        this.listData.rowActions.visible.utility,
                        this.listData.rowActions.visible.action,
                        this.listData.list[i]
                    );

                  }
                }
              }catch (e) {

              }


            },
            100
        )
      }


    },

    copyPreloadToList() {
      if (this.listData.preloadList) {
        for (const i in this.listData.preloadList) {
          this.addToList(this.listData.preloadList[i]);
        }
        this.sortList();
        this.listData.preloadList = [];
      }
    },
  },
  watch: {

  },
}
</script>

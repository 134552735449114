<template >
  <!-- modal start-->
  <div v-if="modalOpen && modalOpen.config && modalOpen.config.display">

    <modal-window
        :title="modalOpen.title"
        :config="modalOpen.config"
    >
      <template #modal-content-slot>
        <!--
        <display-debug>
          <template #debugView>modalOpen {{modalOpen}}</template>
        </display-debug>
        -->
        <component
            v-bind:is="'ContentIncludeView'"
            :router="modalOpen.router"
            :return-data="modalOpen.data"
            :form-prefill="modalOpen.prefill"
            :config="modalOpen.config"
        />

      </template>
    </modal-window>
  </div>
  <!-- modal end-->



  <div
      v-if="typesContentList"
      class="list-group"
  >
    <draggable
        :list="typesContentList"
        itemKey="key_id"
        handle=".move"
        :ghost-class="getConfig('general.style.class_drag_n_drop')"
    >
      <template #item="{ index, element }">
        <div
            class="list-group-item"
        >
          <div
              class="d-flex"
          >
           <div
              class="flex-fill text-primary fs-5 fw-lighter"
            >
              {{element.nazev}}
            </div>

            <div>
              <span
                  v-if="element.je_vytvoreni"
                  class="btn btn-sm btn-outline-secondary ms-auto me-1"
                  @click.prevent="createNewItem(index, element)"
              >
                 <i class="bi bi-plus"></i>
              </span>
              <span
                  v-if="element.je_povinne == false"
                  class="btn btn-sm btn-outline-secondary ms-auto me-1"
                  @click.prevent="removeTypeFromTemplate(index)"
              >
                <i class="bi bi-x"></i>
              </span>
                <span class="btn btn-sm btn-outline-secondary move ms-auto">
                <i class="bi bi-arrows-move"></i>
              </span>
            </div>
          </div>

          <template
              v-if="element.ident == 'text'"
          >
            <wiki-input-simple
                v-model="element.value"
                :parent-index="index"
                :options="{inputType: 'text'}"

            />

          </template>
          <template
              v-if="element.ident == 'url'"
          >
            <wiki-url-input
                v-model="element.value"
                :parent-index="index"
                :options="{inputType: 'text', meta: element.meta}"
            />
          </template>

          <template
              v-if="element.ident == 'textarea'"
          >
            <wiki-textarea-lang
                v-model="element.value"
                :langs="inputObj.langs_def"
                :parent-index="index"
            />
          </template>
          <template
              v-if="element.ident == 'number'"
          >
            <wiki-input-simple
                v-model="element.value"
                :parent-index="index"
                :options="{inputType: 'number'}"
            />
          </template>
          <template
              v-if="element.ident == 'datum'"
          >
            <wiki-input-simple
                v-model="element.value"
                :parent-index="index"
                :options="{inputType: 'date'}"
            />
          </template>

          <template
              v-if="element.ident == 'slug'"
          >
            <wiki-text-lang
                v-model="element.value"
                :langs="inputObj.langs_def"
                :parent-index="index"
                :options="{is_slug:true,makeFrom: element.makeFrom}"
            />
          </template>

          <template
              v-else-if="element.ident == 'text_lang'"
          >
            <wiki-text-lang
              v-model="element.value"
              :langs="inputObj.langs_def"
              :parent-index="index"
            />
          </template>
          <template
              v-else-if="element.ident == 'image'"
          >
            <image-component
                v-model="element.value"
                :type-id="getConfig('wiki.image_id')"
                :is_browser="true"
                :is_upload="true"
                :is_image_props="true"
                :is_input_title="true"
                :is_input_copyright="true"
                :is_delete="true"
                :is_preview_only="false"
                thumb_class="nahled"
            />
          </template>
          <template
              v-else-if="element.ident == 'select'"
          >
            <wiki-select-smart
                v-model="element.value"
                :meta="element.meta"
            />
          </template>
          <template
              v-else-if="element.ident == 'pair'"
          >
            <wiki-pair-input
                v-model="element.value"
                :langs="inputObj.langs_def"
                :parent-index="index"
                :options="{meta: element.meta}"
            />

          </template>
          <template
              v-else-if="element.ident == 'select_simple'"
          >
            <wiki-select
                v-model="element.value"
                :options="element.options"
                :parent-index="index"
            />

          </template>
          <template
              v-else-if="element.ident == 'embed'"
          >
            <wiki-embed
                v-model="element.value"
                :type="element.meta.type"
                :parent-index="index"
            />

          </template>


          <!-- inputs wrap end -->
          <div
              v-if="element.versions && element.versions.length > 0"
              class="mt-2"

          >


            <div class="dropdown d-flex">
              <span
                  @click.prevent="element.isOpen = !element.isOpen"
                  class="btn btn-link btn-sm text-danger text-decoration-none  dropdown-toggle"
              >
                <i class="bi bi-clock me-2"></i>
                Verze
              </span>
              <span
                  v-if="element.isOpen && element.isChanged"
                  class="ms-auto btn btn-sm btn-outline-danger"
              >
                <i class="bi bi-clock me-2"></i> obnoveni
              </span>
            </div>
            <div
                v-if="element.isOpen"
                class="list-group"
            >
              <div
                  v-for="version in element.versions"
                  class="list-group-item"
              >
                  <div class="d-flex ">
                   <span class="fs-6 text-secondary">
                      <small
                          v-format-date.onlyTime="version.datum"
                          class="ms-1"
                      ></small>
                      <small
                          class="ms-1"
                      >|</small>
                      <small
                          v-format-date:custom.time.timeOnly="version.datum"
                          class="ms-1"
                      ></small>




                    </span>
                    <small class="ms-auto" title="Autor">
                        <i class="bi bi-person me-1"></i>
                        {{version.autor}}
                    </small>

                  </div>
                <div>
                  <small
                      v-for="item in displayRevertChanges(version.values)"
                      class="text-danger d-block"

                  >
                     {{item}}
                  </small>

                  <div class="d-flex">
                  <span
                      @click.prevent="revertValue(element, version.values);element.isChanged = true;"
                      class="btn btn-sm btn-outline-danger ms-auto"
                  >
                      Vratit hodnotu <i class="bi bi-arrow-return-left"></i>
                  </span>
                  </div>
                </div>
              </div>
            </div>

            <!--
            <display-debug>
              <template #debugView>element.versions {{element.versions}}</template>
            </display-debug>

                        <display-debug>
                          <template #debugView>element.versions {{element.versions}}</template>
                        </display-debug>
            -->
          </div>
        </div>


      </template>
    </draggable>
  </div>

  <div
      v-if="activeBtns"
      class="-btn-group mt-3"
  >

    <div
        v-for="(item) in activeBtns"
        class="btn btn-sm btn-outline-primary me-1 mb-1"
        @click.prevent="addTypeToTemplate(item)"
    >
      {{item.nazev}}
    </div>
  </div>


  <div
      v-if="Object.keys(getRemovedVersionFormatedList()).length"
      class="list-group mt-2"
  >
    <div class="d-flex">
        <span
            @click.prevent="versionDeleted.isOpen = !versionDeleted.isOpen"
            class="btn btn-link btn-sm text-danger text-decoration-none  dropdown-toggle"
        >
          <i class="bi bi-clock me-2"></i>
          Verze odstranene
        </span>

    </div>

    <div
        v-if="versionDeleted.isOpen"
        v-for="(version, index) in getRemovedVersionFormatedList()"
        class="list-group-item"
    >
      <div
        v-if="version.definice"
        class="text-danger"
      >
       {{version.definice.nazev}}
      </div>
      <small
          v-for="(value, i) in version.values"
          class="text-danger"
      >
        {{i}}: {{value}}
      </small>

      <div class="d-flex">
        <div
            class="btn btn-sm btn-outline-danger ms-auto"
            @click.prevent="revertRemovedVersion(index, version)"
        >
          Vratit polozku
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let timer;
import axios from "axios";
import draggable from "vuedraggable";
import ContentIncludeView from "@/views/ContentIncludeView";
import ModalWindow from "@/components/content/ModalWindow";
import ImageComponent from "@/components/general/Image";
import WikiSelectSmart  from "@/components/general/form/inputs/wiki/SelectSmart";
import WikiTextLang  from "@/components/general/form/inputs/wiki/TextLang";
import WikiInputSimple  from "@/components/general/form/inputs/wiki/InputSImple";
import WikiTextareaLang  from "@/components/general/form/inputs/wiki/TextareaLang";
import WikiPairInput  from "@/components/general/form/inputs/wiki/PairInput";
import WikiUrlInput  from "@/components/general/form/inputs/wiki/InputURL";
import WikiSelect  from "@/components/general/form/inputs/wiki/InputSelect";
import WikiEmbed  from "@/components/general/form/inputs/wiki/InputEmbed";
import VersionControlPartsInput from '@/components/general/form/inputs/_common/PartsVersionControl'

export default {
  name: "WikiParts",
  components: {
    draggable,
    ContentIncludeView,
    ModalWindow,
    VersionControlPartsInput,
    ImageComponent,
    WikiSelectSmart,
    WikiTextLang,
    WikiInputSimple,
    WikiTextareaLang,
    WikiPairInput,
    WikiUrlInput,
    WikiSelect,
    WikiEmbed,
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [Object, String, Number],
  },
  data() {
    return {
      modelData : {},
      typesContentList : [],
      typesBtnList : [],
      modalOpen: {
        title: null,
        router: {
          modul: null,
          action: null
        },
        config: {
          "display": false,
          "is_header": true,
          "is_close_btn": true,
          "is_footer": true
        },
        data: {
          save: false,
          submit: false,
        },
        callback: {},
        prefill: {},
      },
      inputModel: null,
      timer: null,
      timer_delay: 500,
      is_locked: true,
      versionDeleted: {
        isOpen: false,
        list: {},
      }
    }
  },

  mounted() {
    if(this.inputObj.val) {
      this.typesContentList = this.inputObj.val
    }

    if(this.inputObj.versions) {
      for(const i in this.inputObj.versions) {
        //console.log('wiki parts ----------------------------------------')
        //console.log('wiki parts vers 1', i, this.inputObj.versions[i].value)

        const autor = this.inputObj.versions[i].autor;
        const datum = this.inputObj.versions[i].datum;

        for(const j in this.inputObj.versions[i].value) {
          const indexContent = this.findIndexById(j);
          //console.log('wiki parts vers 1', indexContent)
          if(indexContent >= 0) {
            const itemObj = this.findObjectById(j);

            if(typeof this.typesContentList[indexContent].versions == "undefined"){
              this.typesContentList[indexContent].versions = []
            }


            //console.log('wiki parts vers 2', indexContent, j)
            //console.log('wiki parts vers 2 - values', this.inputObj.versions[i].value[j].value)

            //console.log('wiki parts obj', itemObj)
            const versionProperty = {
              autor: autor,
              datum: datum,
              originalValue: itemObj.value,
              values: {}
            }
            for(const k in this.inputObj.versions[i].value[j]) {
              versionProperty.values[k] = this.inputObj.versions[i].value[j][k];
              //console.log('wiki parts vers 4', j, k, this.inputObj.versions[i].value[j][k])

            }

            //console.log('wiki parts vers 5', j, versionProperty)
            this.typesContentList[indexContent].versions.push(versionProperty)
            this.typesContentList[indexContent].isChanged = false;
            this.typesContentList[indexContent].isOpen = false;
          }else{
            if(
                typeof this.inputObj.versions[i].value[j].type != "undefined"
                && this.inputObj.versions[i].value[j].type == 'removed'
                && typeof this.versionDeleted.list[j] == "undefined"
            ) {
              //console.log('wiki parts vers DELETED D', indexContent, j)
              //console.log('wiki parts vers DELETED - type', this.inputObj.versions[i].value[j])
              this.versionDeleted.list[j] = this.inputObj.versions[i].value[j];
            }


          }
        }
      }
    }
  },

  methods: {

    getRemovedVersionFormatedList() {
      const list = {};
      if(this.versionDeleted.list){
        for (const i in this.versionDeleted.list) {
          const indexContent = this.findIndexById(i);
          if(indexContent == -1) {
            const definice = this.getDefiniceById(this.versionDeleted.list[i].value.wiki_definice_id,)
             list[i] = {
               'id': i,
               //'title': definice.nazev,
               'wiki_definice_id': this.versionDeleted.list[i].value.wiki_definice_id,
               'values': {
                 'value': this.versionDeleted.list[i].value.value,
                 'poradi': this.versionDeleted.list[i].value.poradi,
               },
               "definice": definice,
             }
          }
        }
      }
      return list;
    },

    displayRemovedVersion(index, values){
      if(values) {
        values.definice = this.getDefiniceById(values.wiki_definice_id)

        if( typeof values.definice != "undefined") {
          const item = {
            'id': index,
            'title': values.definice.nazev,
            'values': {
              'value': values.value,
              'poradi': values.poradi,
            },
            'data': values,
          }
          return item;
        }
      }
    },

    revertRemovedVersion(index, values){


      //console.log('dev revertRemovedVersion', index, values)
      const definice = values.definice
      definice.poradi = values.values.poradi
      definice.value = values.values.value;
      definice.origin_id = index;

      //this.typesContentList.push(values.definice)
      let is_alert = true;
      let is_exist = false;
      const addType = Object.assign({}, definice);

      if(addType.je_vicenasobne == false) {
        const existencePoradi = this.typesContentList.findIndex(objekt => objekt.ident === addType.ident)
        if(this.typesContentList.findIndex(objekt => objekt.ident === addType.ident) >= 0) {
          is_exist = true;
        }
      }

      if(!is_exist){
        this.typesContentList.push(addType)
      }else{
        if(is_alert){
          //const indexExist = this.typesContentList.findIndex(objekt => objekt.ident === addType.ident);
          alert('[Polozka existuje v sablone.] ' ) //TODO
        }
      }

    },

    getDefiniceById(id){
      const obj = this.typesBtnList.find((objekt) => objekt.definice_id === id);
      return obj;
    },

    displayRevertChanges(values){
      const changes = {};
      if(values){
        for (const i in values) {
          if(typeof values[i].type != "undefined") {
            changes[i]= this.displayChangeTitle(values[i]);
          }else{
            for (const j in values[i]) {
              changes[i+"."+j] = this.displayChangeTitle(values[i][j]);
            }
          }
        }
      }
      return changes;
    },

    displayChangeTitle(item) {
      if(item.type == "change") {
        return "zmena: "+ item.k +": "+ item.from + " > "  +  item.to;
      }else if(item.type == "create") {
        return "vytvoreno: "+ item.k +": " +  item.to;
      }else if(item.type == "removed") {
        return "odstraneno: "+ item.k +": " +  item.from;
      }else{
        console.log('wiki part displayChangeTitle UNDEFINED ', item)
      }

    },

    revertValue(element, values) {
      let isChangePoradi = false;
      const index = this.findIndexById(element.origin_id);
      //console.log('wiki part ------------------------------ ', element.origin_id, index)

      if(values){
        for(const v in values){
          //console.log('wiki part values >', v, values[v] )
          if(v  == 'value'){
            element.isChanged = true;
            //console.log('wiki part VALUE', values[v] )
            if(typeof values[v].from !="undefined"){
              //console.log('wiki part VALUE + FROM', values[v] )
              console.log('dev.revertValue v0', index, values[v].from )
              this.typesContentList[index].value = values[v].from;
            }else{
              //console.log('wiki part VALUE + ITERACE', values[v] )
              for(const i in values[v]) {
                //console.log('wiki part VALUE', i, values[v][i] )
                console.log('dev.revertValue v1', i, values[v][i].from )
                this.typesContentList[index].value[i] = values[v][i].from;
              }
            }



          }else if(v  == 'poradi') {
            isChangePoradi = true;
            //console.log('wiki part PORADI', values[v].from )
            console.log('dev.revertValue x-poradi', values[v].from )
            this.typesContentList[index].poradi = values[v].from;
          }

        }
      }
      if(isChangePoradi === true) {
        this.sortByPoradi();
      }

    },

    sortByPoradi() {
      this.typesContentList.sort((a, b) => a.poradi - b.poradi);
    },

    findIndexById(id) {
      const index = this.typesContentList.findIndex((objekt) => objekt.origin_id === id);
      return index;
    },

    findObjectById(id) {
      const obj = this.typesContentList.find((objekt) => objekt.origin_id === id);
      return obj;
    },

    createNewItem(index, item) {


      if(typeof item.meta.modal != "undefined") {

        this.modalOpen.config.display = true;
        this.modalOpen.title = 'Vytvor novou polozku';
        this.modalOpen.router = item.meta.modal.router

        if(item.meta.modal.prefill) {
          this.modalOpen.prefill = item.meta.modal.prefill;
        }else{
          this.modalOpen.prefill ={
            general: {
              inputs: {}
            }
          }
        }

        //parent id prefill
        if(this.formObj.general.inputs.id.val){
          this.modalOpen.prefill.general.inputs.parent_id = {
            readonly: true,
            value: this.formObj.general.inputs.id.val
          }
        }
      }

      this.modalOpen.callback = {
        index: index
      }


//todo in
      //console.log('dev createNewItem', index, item)
    },

    addTypeToTemplate(type, is_alert = true) {
      const addType = Object.assign({}, type);
      let is_exist = false;
      if(addType.je_vicenasobne == false) {
        const existencePoradi = this.typesContentList.findIndex(objekt => objekt.ident === addType.ident)
        if(this.typesContentList.findIndex(objekt => objekt.ident === addType.ident) >= 0) {
          is_exist = true;
        }
        //is_exist = this.typesBtnList.some(objekt => objekt.definice_id === addType.definice_id);
      }

      if(!is_exist){
        this.typesContentList.push(addType)
      }else{
        if(is_alert){
          //const indexExist = this.typesContentList.findIndex(objekt => objekt.ident === addType.ident);
          alert('[Polozka existuje v sablone.] ' ) //TODO
        }
      }
      //this.typesContentList.push(type)
    },

    removeTypeFromTemplate(index) {
      this.typesContentList.splice(index,1)
    },

    async loadTypes(typ_id) {
      //console.log('dev.loadTypes', typ_id)
      axios.defaults.baseURL = this.getBaseRoot();
      let apiPath = this.getApiConfig('wiki.load_types.path') + "?" + this.getApiConfig('wiki.load_types.input.type') + "=" + typ_id

      axios({
        url: apiPath,
        method: this.getApiConfig('wiki.load_types.method'),
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {

            if(res.data.response.list.data) {
              for(const i in res.data.response.list.data) {
                //btn
                this.typesBtnList.push(res.data.response.list.data[i]);
              }
              //content
              for(const j in res.data.response.list.data) {
                if(res.data.response.list.data[j].je_povinne == true) {
                  let isExist = false;

                  if(res.data.response.list.data[j].je_vicenasobne == false) {
                    isExist = this.typesContentList.some(objekt => objekt.definice_id === res.data.response.list.data[j].definice_id);
                  }

                  if(!isExist) {
                    const newType = Object.assign({}, res.data.response.list.data[j])
                    newType.value = {};
                    this.typesContentList.push(newType)
                  }
                }
              }
            }else{
              this.typesBtnList = [];
              this.typesContentList = [];
            }
          })
          .catch((err) => {
            this.typesBtnList = [];
            this.typesContentList = [];
          })
          .finally(() => {
            //this.galerie.is_changed = false;
            //console.log("DEV tags finally");
          })
    },

    cleanOutputData() {
      const result = [];
      if(this.typesContentList) {
        for(const i in this.typesContentList){
          const item = {

            value: this.typesContentList[i].value,
            typ_id: this.typesContentList[i].typ_id,
            definice_id: this.typesContentList[i].definice_id,
            poradi: parseInt(i)+1,
            id: (typeof this.typesContentList[i].id != "undefined")? this.typesContentList[i].id: null,
            origin_id: (typeof this.typesContentList[i].origin_id != "undefined")? this.typesContentList[i].origin_id: null,
          }
          result.push(item)
        }
      }
      //console.log('dev cleanOutputData', result)
      return result;
    }
  },

  computed: {
    activeBtns() {
      let btns = [];
      for(const i in this.typesBtnList){
        if(!this.typesBtnList[i].je_vicenasobne) {
          const existencePoradi = this.typesContentList.findIndex(objekt => objekt.ident === this.typesBtnList[i].ident)
          if(existencePoradi < 0) {
            btns.push(this.typesBtnList[i]);
          }
        }else{
          btns.push(this.typesBtnList[i]);
        }
      }
      return btns;
    }
  },

  watch: {
    "modalOpen.data": {
      handler(newVal) {
        if(newVal){
          /*
          const btn = {
            'id': newVal.id,
            'nazev': newVal.nazev.cz,
          };

          const section = {
            'id': newVal.id,
            'nazev': newVal.nazev.cz,
            'meals': [],
            'form_display': false,
            '_is_publish': false,
            '_is_open': true,
          };

          this.btnSectionNew.push(btn);
          this.structure.push(section);
*/


          //this.typesContentList[this.modalOpen.callback.index].value = newVal.id
          //this.typesContentList[this.modalOpen.callback.index] =
          this.modalOpen.data.submit = true;
          this.modalOpen.data.saved = true;
          this.modalOpen.config.display = false;
          const item = this.typesContentList[this.modalOpen.callback.index]
          if(item){
            item.value = newVal.id
          }

          //console.log('dev.watch', newVal.id, this.modalOpen.callback.index)
        }


      },
      deep: true,
      immediate: true
    },


    'formObj.general.inputs.typ_id.val': {
      handler(newVal, oldVal) {
        if(newVal) {
          this.typesContentList = [];
          this.typesBtnList = [];
          this.loadTypes(newVal);
        }
          //console.log('dev formObj.general.inputs.typ_id.val watch', newVal, oldVal)
      },
      deep: true,
      immediate: true
    },
    typesContentList: {
      handler(newVal) {
        if(timer) clearInterval(timer);
        timer = setTimeout(
            () => {
              let poradi = 1
              if(newVal){
                for(const i in newVal){

                  if(newVal[i].ident == 'slug') {
                    const index = newVal.findIndex(objekt => objekt.ident === 'text_lang');
                    if(index >=0 ){
                      newVal[i].makeFrom = newVal[index].value; //todo lepsi podminka
                    }
                    //console.log('dev  watch typesContentList slug', newVal[i], index)
                  }

                  newVal[i].poradi = poradi;
                  poradi++;
                }
              }

              this.$emit('update:modelValue', this.cleanOutputData());
            },
            1000
        )

      },
      deep: true,
      immediate: true
    },

    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){
      console.log('dev inputModel watch', newVal)
    },
  },
};
</script>
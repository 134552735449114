<template >
  <div >
    <!--
    <h2>TextInput - {{inputObj.title}} [{{inputObj.display}}]</h2>
    -->
    <input
        :type="this.inputObj.type"
        v-model="inputModel"
        :required="this.inputObj.required"
        :disabled="(this.inputObj.readonly)? 'disabled': false"
        :readonly="(this.inputObj.readonly)? 'readonly': false"
        @click="inputClick"
        @change="setOffPrefill"
        @input="setOffPrefill"
        :class="'form-control' + (this.inputObj.invalid? ' is-invalid': '')"
    />
  </div>

  <div v-if="inputObj.type == 'redirect_target'" class="mt-1">
    <template v-for="item in inputObj.params.btn_params">
    <span @click.prevent="addUtmParams(item.params)"
      class="btn btn-sm btn-outline-secondary me-1"
    >
      <i
          v-if="item.ico"
          :class="item.ico + ' me-1'"
      ></i>
      {{item.title}}
    </span>
    </template>
  </div>

  <version-control-input
      v-if="inputObj.versions"
      v-model="inputModel"
      :versions="inputObj.versions"
  />



  <!--
  <pre>{{inputObj}}</pre>
  -->
</template>

<script>

import axios from "axios";
import VersionControlInput from '@/components/general/form/inputs/_common/VersionControl'
import CountryFlag from "vue-country-flag-next";

export default {
  name: "TextInput",
  components: {
    VersionControlInput,
  },
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  data() {
    return {
      inputModel: null,
      timer: null,
      timer_delay: 500,
      is_locked: true,
    }
  },
  mounted() {
    this.inputModel = this.inputObj.val;
    if (this.inputObj.val && this.inputObj.val.length > 0){
      this.inputObj.is_locked = true;
    }else {
      this.inputObj.is_locked = false;
    }


    //redirect hash prefill
    if(
        this.inputObj.type == "redirect_path"
        && (this.inputModel == undefined || this.inputModel == '' )
    ) {
        this.createREdirectHash();
    }


  },
  methods: {
    setOffPrefill(){
      if(this.inputObj.is_prefill) {
        this.inputObj.is_prefill = false;
      }

    },
    addUtmParams(utmPrefill) {
      let path = ''
      let pathQueryObj = {}

      let inputContent = this.inputModel
      inputContent.trim()

      if(inputContent != '') {

        try {
          const url = new URL(inputContent);
          pathQueryObj = Object.fromEntries(url.searchParams.entries());
          path = url.origin + url.pathname
        } catch (e) {
          let [pathParse , paramString ] = inputContent.split( '?' );
          path = pathParse;
          pathQueryObj = Object.fromEntries(new URLSearchParams( paramString).entries());
        }

        for (const i in utmPrefill) {
          if(pathQueryObj[i] == undefined) {
            pathQueryObj[i] = utmPrefill[i];
          }
        }

        if (Object.keys(pathQueryObj).length > 0) {
          let queryArr = [];
          for (const i in pathQueryObj) {
            queryArr.push(i + '=' + pathQueryObj[i])
          }

          this.inputModel = path + '?' + queryArr.join('&')
        }else{
          this.inputModel = path
        }

      }else{
        alert("Nejdriv vyplnte cilovou adresu")
      }
    },

    async createREdirectHash(){
      let hash;
      axios.defaults.baseURL = this.getBaseRoot();
      await axios({
        url: this.getApiConfig('redirect.get_new_hash.path'), //this.cfg.api.redirect.get_new_hash.path,
        method: this.getApiConfig('redirect.get_new_hash.method'),// this.cfg.api.redirect.get_new_hash.method,
        withCredentials: true,
        headers: this.getRequestHeader(),
      }).then((res) => {
        hash = res.data.response.hash
      }) .catch((err) => {

        console.log("dev prefill catch", err)

      })
      .finally((f) => {
        if(hash) {
          this.inputModel  = hash
        }
      })
    },

    createSlugFormat(input){
      if(input) {

        const translate_map = {
          'á': 'a',
          'ä': 'a',
          'å': 'a',
          'č': 'c',
          'ç': 'c',
          'ď': 'd',
          'é': 'e',
          'ě': 'e',
          'ë': 'e',
          'í': 'i',
          'ň': 'n',
          'ó': 'o',
          'ö': 'o',
          'ř': 'r',
          'š': 's',
          'ť': 't',
          'ú': 'u',
          'ů': 'u',
          'ü': 'u',
          'ý': 'y',
          'ž': 'z',
          '+': '-',
        }
        input = input.toLowerCase().trim()
        input =  input.replace(/[^A-Za-z]/g, x => translate_map[x] || x)
        input = input.replace(/[^\w\s-]/g, '')
        input = input.replace(/[\s_-]+/g, '-')
        input = input.replace(/^-+|-+$/g, '')
      }
      return input;
    },

    createPathFormat(input){
      if(input) {

        const translate_map = {
          'á': 'a',
          'ä': 'a',
          'å': 'a',
          'č': 'c',
          'ç': 'c',
          'ď': 'd',
          'é': 'e',
          'ě': 'e',
          'ë': 'e',
          'í': 'i',
          'ň': 'n',
          'ó': 'o',
          'ö': 'o',
          'ř': 'r',
          'š': 's',
          'ť': 't',
          'ú': 'u',
          'ů': 'u',
          'ü': 'u',
          'ý': 'y',
          'ž': 'z',
          '+': '-',
        }
        input = input.toLowerCase().trim()
        input =  input.replace(/[^A-Za-z]/g, x => translate_map[x] || x)
        //input = input.replace(/[^\w\s-]/g, '')
        input = input.replace(/[\s_-]+/g, '-')
        input = input.replace(/^-+|-+$/g, '')
      }
      return input;
    },

    inputClick() {
      if(this.inputObj.slug && this.inputObj.slug.is_slug){
        this.inputObj.slug.disable_auto_fill = true;
        this.inputObj.is_locked = true;
      }
    }
  },
  watch: {
    inputObj: {
      handler() {
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){

      if(this.inputObj.name == 'slug' && newVal.length == 0){
        this.inputObj.is_locked = false;
      }
      //timer
      if(this.timer) clearInterval(this.timer);
      const delay = this.timer_delay
      const pseudo_this = this;
      this.timer = setTimeout(
          () => {
          //start timer function content
            //tohle je slug input a jeho prevod na spravny format
            if (
                typeof pseudo_this.inputObj.slug != 'undefined'
                && typeof pseudo_this.inputObj.slug.is_slug != 'undefined'
            ){
              //console.log("dev slug inputObj SLUG format")
              newVal = pseudo_this.createSlugFormat(newVal);
              this.inputModel = newVal;
              this.inputObj.val = newVal
            }else if(
                typeof pseudo_this.inputObj.path_format != 'undefined'
                && typeof pseudo_this.inputObj.path_format.is_path_format != 'undefined'
            ){
              newVal = pseudo_this.createPathFormat(newVal);
              this.inputModel = newVal;
              this.inputObj.val = newVal
              console.log('dev patha', newVal)
            }else{
              this.inputObj.val = newVal

            }

            //pokud z inputu vytvarim dalsi inpu ve formatu slug

            if(
                typeof pseudo_this.inputObj.slug != 'undefined'
                && typeof pseudo_this.inputObj.slug.section != 'undefined'
                && typeof pseudo_this.inputObj.slug.input != 'undefined'
                && typeof pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].disable_auto_fill == 'undefined'
              && pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].is_locked == false
            ) {
              pseudo_this.formObj[pseudo_this.inputObj.slug.section].inputs[pseudo_this.inputObj.slug.input].val = newVal
            }



            //update parent
            //console.log('dev textarea', newVal)
            pseudo_this.$emit('update:modelValue', newVal )
          //end timer function content
          },
          this.timer_delay
      )
    },
  },
};
</script>
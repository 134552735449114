<template>
  <ul
      v-if="options"
  >
    <template
        v-for="(item) in options"
    >
      <li
        :class="+ isSelected(item)? 'selected-item': '' "
        :ref="'polozka-' + item.id"
      >
        <div class="tree-item">
          <!-- rozbalovani -->
          <slot
             name="itemSwitch"
              :item="item"
          >
            <span
              v-if="item.children"
              class="prefix"
          >
          <span
              v-if="item.children"
              @click="(event) => toggleDisplayChildren(item, event)"
              class="me-2"
          >
          <i
              v-if="!item.children_show"
              class="bi bi-caret-right text-muted">
          </i>
          <i
              v-else-if="item.children_show"
              class="bi bi-caret-down-fill text-muted">
          </i>


        </span>
          </span>
            <span
                v-else
                class="prefix">
            &nbsp;
          </span>
          </slot>

          <slot
            v-if="typeof item.isDisabled == 'boolean' && item.isDisabled"
            name="itemDisabled"
            :item="item"
          >
             <span
              :class="'label disabled '+ (item.isSelectedValue?' selected': '')"
            >
              {{item.label}}
              <i class="bi bi-ban"></i>
            </span>
          </slot>

          <slot
              v-else
              name="itemNormal"
              :item="item"
          >
            <span
              @click="inputModel=item;"
              @keydown.enter="inputModel=item;console.log('key wnter', item)"
              :class="'label '+ (item.isSelectedValue?' selected': '')"
              tabindex="0"
            >
              {{item.label}}
            </span>
          </slot>
        </div>

        <template
            v-if="(item.children && item.children_show) || item.isOpen"
        >
          <five-tree-select-item
              v-model="inputModel"
              :options="item.children"
          >
            <template #itemDisabled><slot name="itemDisabled" :item="item"></slot></template>
            <template #itemNormal><slot name="itemNormal" :item="item"></slot></template>
            <template #itemSwitch><slot name="itemSwitch" :item="item"></slot></template>
          </five-tree-select-item>
        </template>
      </li>
    </template>
  </ul>
</template>



<script>
export default {
  name: "fiveTreeSelectItem",
  props: {
    "modelValue": {
      type: [String, Number, Object],
      required: false,
    },
    "options": {
      type: [Array, Object],
      required: false,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      inputModel: null,
    }
  },
  mounted() {
    this.inputModel = this.modelValue;
  },
  methods: {
    toggleDisplayChildren(item, event){
      event.stopPropagation();
      item.children_show = !item.children_show
      //console.log('dev.toggleDisplayChildren', event, typeof event)

    },
    isSelected(item){
      if(
          this.modelValue
          && typeof this.modelValue.id
          && this.modelValue.id == item.id
      ) {
        return true;
      }else{
        return false;
      }

    },
  },
  watch: {
    inputModel: {
      handler(newVal, oldVal) {
        if(newVal !== null){
          this.$emit('update:modelValue', newVal)
        }
      },
      deep: true,
      immediate: true
    },
  },
}
</script>

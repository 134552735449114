<template >
  <div >
    <!--
        <display-debug>
          <template #debugView>TreeSelectInput [Parent] inputModel {{inputModel}}</template>
        </display-debug>
        <display-debug>
          <template #debugView>TreeSelectInput [Parent] modelValue {{modelValue}}</template>
        </display-debug>
         -->
    <five-tree-select
        v-model="inputModel"
        :options="inputObj.templates.data"
        placeholder="Vyberete kategorii"
    />
  </div>

<!--
  <display-debug>
    <template #debugView>TreeSelectInput [Parent] inputModel {{inputModel}}</template>
  </display-debug>



  <treeselect
      v-model="inputModel"
      :options="inputObj.templates.data"
      :searchable="true"
      placeholder="Vyberete kategorii"
      :flattenSearchResults="true"
      :normalizer="normalizer"
  >
    <template #value-label="{ node }">
      <span v-html="node.raw.label"></span>
    </template>


    <template #option-label="{ node }">
        <span class="custom-option">
          <span v-html="node.label"></span>
        </span>
    </template>

  </treeselect>
-->
</template>


<script>

//import 'vue3-treeselect/dist/vue3-treeselect.css'
import Treeselect from 'vue3-treeselect'
import fiveTreeSelect from "@/components/content/fiveTreeSelect";
export default {
  name: "TreeSelectInput",
  props: {
    "inputObj": Object,
    "formObj": Object,
    "modelValue": [String, Number],
  },
  components: {
    Treeselect,
    fiveTreeSelect,
  },

  data() {
    return {
      inputModel: null,
      dev: null,
    }
  },
  created() {
    console.clear() //DEV
    //console.log('[fiveTreeSelect] created');
  },
  mounted() {
    this.inputModel = this.inputObj.val;
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.label + (!node.isDisabled? ' <strong>[je pro eshop]<strong>': ''),
        children: node.children,
        dev: node.isDisabled,
        isDisabled: false,
      }
    }
  },
  watch: {
    inputObj: {
      handler() {
        //console.log("[TreeSelectInput] TREE_CHANGE", this.inputObj.val, typeof this.inputObj.val)
        this.inputModel = this.inputObj.val;
      },
      deep: true,
      immediate: true
    },
    inputModel( newVal){
      //console.log("[TreeSelectInput] inputModel", newVal)
      this.inputObj.val = newVal;
      this.$emit('update:modelValue', newVal )
    },

  },
};
</script>
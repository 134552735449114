export const api = {
    "dev_root": "http://fifth.api/v",
    "root": "https://dev-api.jiri5.cz/v1",
    "header_name": "X-Fifth-Cms-User",
    "user": {
        //"login_path": "/class/user/login",
        //"x_login_check": "/class/user/check-user",
        //"logout_path": "/class/user/logout",
        "preview": {
            "path": "/class/user/preview?user_id="
        },
        "select_list_service": {
            "method": "get",
            "path": "/class/user/list-template",
            "input": {
                "id": "galerie_id"
            }
        },
        "create_reset_request": {
            "method": "post",
            "path": "/class/user/create-reset-request",
            "input": {
                "login": "login",
            }
        },
        "password_reset_check_hash": {
            "method": "post",
            "path": "/class/user/password-reset-check-hash",
            "input": {
                "user_id": "user_id",
                "hash": "hash",
            }
        },
        "password_reset_setup_new": {
            "method": "post",
            "path": "/class/user/password-reset-setup-new",
            "input": {
                "user_id": "user_id",
                "hash": "hash",
                "password": "password",
                "password_confirm": "password_confirm",
            }
        },
        "check_password": {
            "method": "post",
            "path": "/class/user/check-password",
            "input": {
                "password": "password",
                "password_confirm": "password_confirm",
            }
        },

        "login": {
            "method": "get",
            "path": "/class/user/login",
            "input": {
            }
        },
        "logout": {
            "method": "post",
            "path": "/class/user/logout",
            "input": {
            }
        },
        "login_check": {
            "method": "get",
            "path": "/class/user/check-user",
            "input": {
            }
        },
        "check_messages": {
            "method": "post",
            "path": "/class/user/check-password",
            "input": {
                "password": "password",
                "password_confirm": "password_confirm",
            }
        },
        "check_flash_messages": {
            "method": "get",
            "path": "/class/flash-message/notifications",
        },
        "read_flash_messages": {
            "method": "get",
            "path": "/class/flash-message/notification-read",
            "input": {
                "flash_id": "flash_id",
            }
        },

        "preview_NEW": {
            "method": "get",
            "path": "/class/user/preview?user_id=",

        },


    },
    "general": {
        "main_menu": {
            "method": "get",
            "path": "/class/general/main-menu",
        },

        //"main_menu_path": "/class/general/main-menu",
        "dashboards": {
            "method": "get",
            "path": "/class/general/dashboard",
        },
        "format_slug": {
            "method": "get",
            "path": "/class/general/format-slug",
            "input": {
                "slug": "slug"
            }
        },
    },
    "gallery": {
        "preview": {
            "method": "get",
            "path": "/class/galerie/preview",
            "input": {
                "id": "galerie_id"
            }
        },
        "browse_service": {
            "method": "get",
            "path": "/class/galerie/list-select",
            "input": {
                "query": "q"
            }
        },
        "images_service": {
            "method": "get",
            "path": "/class/galerie/image-list",
            "input": {
                "query": "id"
            }
        },
        "save_service": {
            "method": "post",
            "path": "/class/galerie/save-simple",
        },
        "upload_service": {
            "method": "post",
            "path": "/class/galerie/upload",
            "input": {
                "file_name": "image_file"
            }
        },
        "create_service": {
            "method": "post",
            "path": "/class/galerie/create-simple",
            "input": {
                "title": "gallery_title",
                "validation": "validation"
            }
        },
        "server_upload_list": {
            "method": "post",
            "path": "/class/galerie/server-upload",
            "input": {
                "title": "gallery_title",
                "validation": "validation"
            }
        },
        "stats_service": {
            "method": "get",
            "path": "/class/galerie/gallery-stat",
            "input": {
                "di": "gallery_id",
            }
        },
    },
    "image": {
        "upload_service": {
            "method": "post",
            "path": "/class/image/upload",
            "input": {
                "file_name": "image_file"
            }
        },
        "save_info": {
            "method": "post",
            "path": "/class/image/save-info",
            "input": {
                "image": "image"
            }
        },
        "get_info_tags": {
            "method": "get",
            "path": "/class/image/image-tags?image_id=",
        },
        "server_upload_service": {
            "method": "post",
            "path": "/class/image/server-upload",
            "input": {
                "name": "file_name",
                "file_path": "image_path",
                "image_type": "image_type",
                "delete": "delete_after_upload",
                "validate": "image_validation",
            }
        },
        "browse_service": {
            "method": "get",
            "path": "/class/image/image-select-list",
            "input": {
                "type": "typ_id",
                "query": "q"
            }
        },
        "preview": {
            "path": "/class/image/preview?image_id=",
            "method": "get",
        },
        "detail_sizes": {
            "method": "get",
            "path": "/class/image/detail-sizes?image_id=",
        },
        "server_upload_thumbnail": {
            "path": "/class/image/display-thumbnail-upload?file="
        },
    },
    "obsah": {
        "link": {
            "path": "/class/obsah/clanek-select-list?q="
        },
        "create_anotation": {
            "method": "post",
            "path": "/class/obsah/create-anotation",
            "input": {
                "segment": "segment"
            }
        },
        "scan_text": {
            "method": "post",
            "path": "/class/obsah/scan-text",
            "input": {
                "text": "text"
            }
        },
        "segments_prefill": {
            "method": "post",
            "path": "/class/obsah/segments-by-id",
            "input": {
                "ids": "ids"
            }
        },
    }  ,
    "wysiwyg": {
        "buttons": {
            "method": "get",
            "path": "/class/obsah/wysiwig-buttons"
        },
        "dev": {
            "path": "/class/obsah/dev-wysiwig-editor-dialog"
        },
    },
    "tags": {
        "create_simple": {
            "path": "/class/tags/create-simple"
        },
        "select_list": {
            "path": "/class/tags/list-select?q="
        }
    },
    "restaurace": {
        "get_structure": {
            "method": "get",
            "path": "/class/restaurace/get-menu-types",
            "input": {
                "id": "id"
            }
        },
        "meal_search": {
            "method": "get",
            "path": "/class/meal/list-select",
            "input": {
                "restaurace": "restaurace_id",
                "query": "q"
            }
        },
        "meal_detail": {
            "method": "get",
            "path": "/class/meal/detail",
            "input": {
                "id": "id",
            }
        },
    },
    "redirect": {
        "get_new_hash": {
            "method": "get",
            "path": "/class/presmerovani/get-new-hash",
        },
    },
    "venue": {
        "get_parent_venue": {
            "method": "post",
            "path": "/class/venue/venue-parent",
            "input": "place",
        },
        "get_venue_formated": {
            "method": "post",
            "path": "/class/venue/venue-formated",
            "input": "place",
        },

        "get_result_list": {
            "method": "post",
            "path": "/class/venue/results-venue-list",
            "input": "list_input",
        },
    },
    "eshop": {
        "get_tax_detail": {
            "method": "get",
            "path": "/class/eshop-tax/detail",
            "input": {
                "id": "id"
            }
        },
        "get_parametr_list": {
            "method": "get",
            "path": "/class/eshop-produkt/parametr-list",
            "input": {
                "id": "id"
            }
        },
        "get_detail_parametr_value": {
            "method": "get",
            "path": "/class/eshop-produkt/parametr-detail",
            "input": {
                "id": "id"
            }
        },
        "add_parametr_value": {
            "method": "post",
            "path": "/class/eshop-produkt/parametr-add",
            "input": {
                "parametr_id": "id",
                "value": "value"
            }
        },

    },
    "rezervace": {
        "view_prehled_slot_detail": {
            "method": "post",
            "path": "/class/rezervace-slot/view-prehled-slot-detail",
            "input": {
                "slot": "slot"
            }
        },
        "slot_change_slot_status": {
            "method": "post",
            "path": "/class/rezervace-slot/change-status",
            "input": {
                "slot_id": "slot_id",
                "stav_id": "stav_id"
            }
        },
    },
    "dashboard": {
        "detail": {
            "method": "get",
            "path": "/class/dashboards/dashboard-detail",
            "input": {
                "id": "id"
            }
        },
    },
    "wiki": {
        "load_types": {
            "method": "get",
            "path": "/class/wiki/type-list",
            "input": {
                "type": "typ_id"
            }
        },
        "check_url": {
            "method": "get",
            "path": "/class/wiki/check-url",
            "input": {
                "type": "type",
                "url": "url"
            }
        },
    },

};

<template>


<!--
  <display-debug>
    <template #debugView>fiveTreeSelect input {{input}}</template>
  </display-debug>

  <display-debug>
    <template #debugView>fiveTreeSelect filteredList {{selectedValue}}</template>
  </display-debug>
  <display-debug>
    <template #debugView>fiveTreeSelect input {{input}}</template>
  </display-debug>
  <display-debug>
    <template #debugView>fiveTreeSelect input {{input}}</template>
  </display-debug>


  <display-debug>
    <template #debugView>fiveTreeSelect filteredList {{flatList}}</template>
  </display-debug>
-->


  <div
    class="tree-select-wrap"

  >
    <!-- input container -->
    <div
        class="tree-select-input form-control p-0"
        ref="inputWrap"
        id="inputWrap"
    >
      <span
          class="input-phrase  flex-grow-1 btn fw-lighter text-start text-muted"
          data-not-click-detect="1"
      >
        <div
            v-if="(input.isValue && selectedValue && selectedValue.id) && !input.isSuggestSearch"
            @click="onClickValue()"
            v-clikoutside="callbackClickOutsideSuggest"
            class="btn w-100 text-muted text-start border-0"
            data-not-click-detect="1"
        >
          {{selectedValue.label}}
        </div>

        <input
            ref="treeInput"
            v-else-if="!input.isValue || input.isSuggestSearch"
            v-model="input.searchPhrase"
            type="text"
            @keydown="applySearch()"
            @focus="onInputFocus()"

            :class="'text-secondary'+ (input.positionClass? (' '+input.positionClass):'') +(input.isValue?' value':'')"
            :placeholder="input.isValue? selectedValue.label : placeholder"
            data-not-click-detect="1"
        />
      </span>

      <span
          v-if="input.isValue"
          @click.prevent="onInputClose()"
          class="input-close  text-center"
          data-not-click-detect="1"
      >
        <i class="bi bi-x-lg text-muted " data-not-click-detect="1"></i>
      </span>
      <div
          @click.prevent="onInputExpand()"
          class="input-expand text-center"
          data-not-click-detect="1"
      >
        <i
            class="bi bi-caret-down-fill text-muted "
            data-not-click-detect="1"
        ></i>
      </div>
    </div>

    <!-- suaggest container -->
    <div
        ref="suggestWrap"
        v-show="input.isSuggestOpen"
        v-clikoutside="callbackClickOutsideSuggest"
        @keydown.enter="onSuggestKeydown()"
        :class="'tree-select-suggest-wrap ' + input.positionClass"
        tabindex="0"
    >
      <!-- search list - start -->
      <div
          v-show="input.isSuggestSearch"
          class="list-wrap search"
          ref="suggestSearchWrap"
      >
        <ul
            v-if="flatList.list && flatList.list.length"
            class="filter-list-wrap"
        >
          <li
              v-for="(item) in flatList.list"
          >
              <span
                  @click.prevent="setValueFromFlatlist(item)"
                  tabindex="0"
              >
                <div class="-fs-5">{{item.label}}</div>
                <template v-if="item.path">
                  <small class="text-muted fs-6 ">
                    <i class="bi bi-arrow-right-short"></i>
                    {{item.path.join('/ ')}}
                  </small>
                </template>

                <!--
                <template v-if="item.path && item.path.length">
                  <div>path2: {{item.path}}</div>
                </template>
-->
              </span>
          </li>
        </ul>

        <div
            v-else-if="input.searchPhrase"
            class="filtered-list-no-result"
        >
          <i class="bi bi-exclamation-triangle text-danger"></i> Zadne vysledky pro <i class="fw-light">{{input.searchPhrase}}</i>
        </div>

      </div>
      <!-- search list - end -->

      <!-- tree list - start -->
      <div
          v-show="input.isSuggestSelect"
          class="list-wrap tree"
          ref="suggestSelectWrap"
          tabindex="0"
      >
        <five-tree-select-item
            v-model="treeList.selected"
            :options="options"
        >
          <!--
          <template #itemSwitch="{item}">
            <span
                  v-if="item.children"
                  class="prefix"
              >
          <span
              v-if="item.children"
              @click="(event) => toggleDisplayChildren(item, event)"
              class="me-2"
          >
          <i
              v-if="!item.children_show"
              class="bi bi-globe text-muted">
          </i>
          <i
              v-else-if="item.children_show"
              class="bi bi-caret-down-fill text-muted">
          </i>


        </span>
          </span>
            <span
                v-else
                class="prefix">
            &nbsp;
          </span>
          </template>

          <template #itemNormal="{item}">
               <span
                   @click="inputModel={id: item.id, label: item.label};"
                   :class="'label '+ (item.isSelectedValue?' selected': '')"
                   tabindex="0"
               >
                {{item.label}}[custom]
                </span>
          </template>


          <template #itemDisabled="{item}" v-bind="{$slots}">
            <span style="color: olive;">parent[itemDisabled]</span>
          </template>
          -->
        </five-tree-select-item>
      </div>
      <!-- tree list - end -->
    </div>
  </div>


</template>

<script>
let timer = {
  config: {
    onValueClick: 10,
    onClose: 10,
    onSwitchSearchInput: 10,
    searchPhrase: 250,
    renderAfter: 50,
    typeDelay: 250,
  },
  object: {
    onValueClick: null,
    onClose: null,
    searchPhrase: null,
    renderAfter: null,
    typeDelay: null,
  }

}
import fiveTreeSelectItem from "./fiveTreeSelectItem";
import "./fiveTreeSelect.scss";
export default {
  name: "fiveTreeSelect",
  props: {
    "modelValue": {
      type: [String, Number, Object],
      required: false,
      default: {
        id: null,
        label: null,
      }
    },
    "options": {
      type: [Array, Object],
      required: false,
    },
    "placeholder": {
      type: [String, Number],
      required: false,
    },
  },
  emits: ['update:modelValue'],
  components: {
    fiveTreeSelectItem,
  },
  data() {
    return {
      inputModel: null,
      selectedValue: null,
      input: {
        isSuggestOpen: false,
        isSuggestSelect: true,
        isSuggestSearch: false,

        isValue: false,
        isClose: false,
        searchPhrase: null,
        positionClass: 'to-down',
      },
      flatList: {
        prelist: {},
        list: [],
      },
      treeList: {
        selected: null,
      },
      devActiveElement: null,
    }
  },
  created() {
    //console.clear() //DEV
    //console.log('[fiveTreeSelect] created');
  },
  directives: {
    'clikoutside': { beforeMount(el, binding) {
        el.clickOutsideEvent = function(event) { // Zkontrolujeme, jestli klik nebyl na element nebo jeho potomky
          //console.log('[fiveTreeSelect] directives - clickOutsideEvent',(el == event.target), el.contains(event.target), event.target.hasAttribute("data-not-click-detect"), event.target);
          if (
             !(el == event.target)
             && !el.contains(event.target)
             && !event.target.hasAttribute("data-not-click-detect")
           ) {
              binding.value(event);
           }
        };
        document.body.addEventListener('click', el.clickOutsideEvent);
      },
      unmounted(el) {
        document.body.removeEventListener('click', el.clickOutsideEvent);
      }
    }
  },

  mounted() {

    //console.log('[fiveTreeSelect] mounted inputModel', this.inputModel);
    //console.log('[fiveTreeSelect] mounted modelValue', this.modelValue);
    this.inputModel = this.modelValue; // klasicky preklonovani abych mohl pouzivat promenou

    //TODO vytvoreni predfiltrovani pro vyhledavani
    this.createPreFlatList();
    //TODO vytvoreni path a open pro stromovou strukturu
    this.createTreeList()
    //TODO vytvoreni vybrane hodnoty pro zobrazeni
    this.createDisplayValue()

  },

  methods: {
    callbackClickOutsideSuggest(event){
      if(this.input.isSuggestOpen === true) {
        console.log('[fiveTreeSelect] [callbackClickOutsideSuggest]', event);
        this.input.isSuggestOpen = false;
       }
    },

    createPreFlatList(){
      //console.log('[fiveTreeSelect] createPreFlatList');
      this.createFlatItem(this.options);
    },

    createFlatItem(item){
      if(item ) {
        for (const i in item) {
          //console.log('[fiveTreeSelect] createFlatItem ITEM', item[i]);
          if(
              typeof item[i].isDisabled == "undefined"
          ) {
            const id = item[i].id
            this.flatList.prelist[id] = {
              id: item[i].id,
              label: item[i].label,
              path: item[i].path,
              isSelectedValue: item[i].isSelectedValue,
            }
            if(item[i].children) {
              this.createFlatItem(item[i].children);
            }
          } else if (!item[i].isDisabled) {
            const id = item[i].id
            this.flatList.prelist[id] = {
              id: item[i].id,
              label: item[i].label,
              isDisabled: item[i].isDisabled,
              path: item[i].path,
              isSelectedValue: item[i].isSelectedValue,
            }
            if(item[i].children) {
              this.createFlatItem(item[i].children);
            }
          }

          /*
          this.filteredList.prelist.push({
            id: item[i].id,
            label: item[i].label,
            isDisabled: item[i].isDisabled,
            path: item[i].path,
            isSelectedValue: item[i].isSelectedValue,
          });
          */

        }
      }
    },

    createTreeList(){
      //console.log('[fiveTreeSelect] createTreeList');
      this.addPathToOption(this.options);
      this.addOpenToOption(this.options);
    },

    addPathToOption(items, path = [], parents = []) {
      //console.log('[fiveTreeSelect] addPathToOption', items);
      if(items && items.length > 0) {
        for (const i in items) {
          //path
          if(path && path.length > 0) {
            items[i].path = path;
            items[i].parents = parents;
          }

          //seleceted
          /*
          if(
              this.selectedValue
              && this.selectedValue.id
          ) {
            if(items[i].id == this.selectedValue.id) {
              this.selectedValue.parents = items[i].parents;
            }
          }*/

          //kids
          if(items[i].children) {
            const pathSent = [...path, items[i].label];
            const parentsSent = [...parents, items[i].id];
            items[i].parents = parentsSent;
            this.addPathToOption(items[i].children, pathSent, parentsSent);
          }else{
            if(parents && parents.length > 0) {
              const parentsSent = [...parents];
              items[i].parents = parentsSent;
            }else{
              items[i].parents = [];
            }
          }
        }
      }
    },

    addOpenToOption(items) {
      if(this.selectedValue && this.selectedValue.id) {
        if(items && items.length > 0) {
          for (const i in items) {
            if(
                this.selectedValue
                && this.selectedValue.parents
                && this.selectedValue.parents.length > 0
            ) {
              if(this.selectedValue.parents.includes(items[i].id)) {
                items[i].isOpen = true;
                //console.log('[fiveTreeSelect] addPathToItem VYBRANO', items[i]);
              }else{
                items[i].isOpen = false;
              }
            }
            if(items[i].children) {
              this.addOpenToOption(items[i].children);
            }

            //selected isSelectedValue
            if(
                this.selectedValue
                && this.selectedValue.id
                && this.selectedValue.id == items[i].id
            ) {
              items[i].isSelectedValue = true;
            } else{
              items[i].isSelectedValue = false;
            }

          }


        }
      }

      /*
      if(this.selectedValue && this.selectedValue.id) {
        if(items && items.length > 0) {
          for (const i in items) {
            //console.log('[fiveTreeSelect] addOpenToOption', items[i].parents);
            if(items[i].parents) {

                if(items[i].parents.includes(this.selectedValue.id)) {
                  console.log('[fiveTreeSelect] addOpenToOption', items[i].id, items[i].parents, items[i].label);
                 // items[i].isOpen = true;
                }
            }
            if(items[i].children) {
              this.addOpenToOption(items[i].children);
            }
          }
        }
      }
      */
    },

    createDisplayValue(){
      if(this.inputModel){
        if(
            this.inputModel
            && typeof this.flatList.prelist[this.inputModel] != "undefined"
        ) {
          const value = this.flatList.prelist[this.inputModel];
          this.selectedValue = {
            id: value.id,
            label: value.label,
            path: value.path,
          }
          this.input.isValue = true;

        } else if (this.inputModel && typeof this.flatList.prelist[this.inputModel] == "undefined") {
          this.inputModel = null;
          this.input.isValue = false;

        }else{
          this.input.isValue = false;

        }
      }
    },

    onInputFocus() {
      //console.log('[fiveTreeSelect] [onInputFocus]');

      this.input.isSuggestOpen = true;
      this.createSuggestPosition();
    },

    onBlurSubsearch() {
      console.log('[fiveTreeSelect] [onBlurSubsearch]', this.input);

      //TODO spravny casovac
      if(timer.object.onSwitchSearchInput) clearInterval(timer.object.onSwitchSearchInput);
      timer.object.onSwitchSearchInput = setTimeout(
          () => {
            this.$refs.treeInput.focus();
          },
          timer.config.onSwitchSearchInput
      )

    },

    onInputClose() {
      console.log('[fiveTreeSelect] [onInputClose]');

      this.input.isSuggestOpen = false;
      this.input.isSuggestSelect = false;
      this.input.isSuggestSearch = false;
      this.input.isValue = false;

      this.inputModel = null,

      this.createDisplayValue();

      if(timer.object.onClose) clearInterval(timer.object.onClose);
      timer.object.onClose = setTimeout(
          () => {
            this.$refs.treeInput.focus()
          },
          timer.config.onClose
      )

    },

    onClickValue() {

      //console.log('[fiveTreeSelect] [onClickValue] document.activeElement', document.activeElement);
      this.onInputExpand()
      this.input.isSuggestSearch = true;

      this.setFocusWithDelay('treeInput');
/*
      if(timer.object.onValueClick) clearInterval(timer.object.onValueClick);
      timer.object.onValueClick = setTimeout(
          () => {
            console.log('[fiveTreeSelect] [onClickValue] this.$refs.searchInput.focus() TIMER', this.$refs);
            this.$refs.searchInput.focus()
          },
          timer.config.onValueClick
      )
*/

    },

    onInputExpand() {

      this.input.isSuggestOpen = !this.input.isSuggestOpen;
      this.input.isSuggestSelect = true;
      this.input.isSuggestSearch = false;

    },



    createSuggestPosition() {
      const inputRect = this.$refs.treeInput.getBoundingClientRect();
      const suggestHeight = 500; // TODO dynamicka vyska
      const spaceAbove = inputRect.top;
      const spaceBelow = window.innerHeight - inputRect.bottom;

      //console.log('[fiveTreeSelect] [createSuggestPosition]', spaceBelow, suggestHeight);

      if (spaceBelow >= suggestHeight) {
        this.input.positionClass = 'to-down';
        //console.log('[fiveTreeSelect] onOpenSuggest DOLU');
      }else if (spaceAbove >= suggestHeight) {
        //console.log('[fiveTreeSelect] onOpenSuggest NAHOIRU');
        this.input.positionClass = 'to-up';
      } else {
        this.input.positionClass = 'to-down';
        //console.log('[fiveTreeSelect] onOpenSuggest NEVIM');
      }
    },

    onKeypressSearch(){
/*
      if(this.input.searchPhrase == '') {
        this.input.searchPhrase = null;
      }

      if(this.input.searchPhrase){
        this.input.isSuggestSearch = true;
        this.input.isSuggestSelect = false;
        this.createSearchFlatList();
        //console.log('[fiveTreeSelect] [onKeypressSearch]', this.input.searchPhrase);
      }else{
        this.input.isSuggestSearch = false;
        this.input.isSuggestSelect = true;
      }
    */
    },

    createSearchFlatList(){
      const list = [];
      const regex = new RegExp(this.input.searchPhrase, "i");
      //console.log('[fiveTreeSelect] createFilteredList', phrase);
      //console.log('[fiveTreeSelect] [onKeypressSearch]', this.input.searchPhrase, this.flatList.prelist.length);
      if(
          this.flatList.prelist
          && Object.keys(this.flatList.prelist).length
      ) {

        for(const i in this.flatList.prelist) {
          if(regex.test(this.flatList.prelist[i].label)){
            list.push(this.flatList.prelist[i])
          }else if(this.input.searchPhrase == null){// || phrase == ''
            list.push(this.flatList.prelist[i])
          }
        }
      }
      this.flatList.list = list;
    },

    setValueFromFlatlist(value) {
      this.input.isSuggestOpen = false;
      this.input.isSuggestSearch = false;
      //console.log('[fiveTreeSelect] setValueFromFlatlist', value);
      this.inputModel = value.id;
      this.createDisplayValue();
    },

    applySearch() {
      if(timer.object.searchPhrase) clearInterval(timer.object.searchPhrase);
      timer.object.searchPhrase = setTimeout(
          () => {

            if(this.input.searchPhrase == '') {
              this.input.searchPhrase = null;
            }



            if(timer.object.typeDelay) clearInterval(timer.object.typeDelay);
            timer.object.renderAfter = setTimeout(
                () => {
                  //console.log('[fiveTreeSelect] applySearch', this.input.searchPhrase);

                  this.input.isSuggestOpen = true;

                  if(this.input.searchPhrase){

                    this.input.isSuggestSearch = true;
                    this.input.isSuggestSelect = false;
                    this.createSearchFlatList();

                  }else{
                    this.input.isSuggestSearch = false;
                    this.input.isSuggestSelect = true;
                  }
                },
                timer.config.typeDelay
            )
          },
          timer.config.searchPhrase
      )
    },
/*
    searchOnValue(){
      console.log('[fiveTreeSelect] searchOnValue', this.input);
    },

    searchOnSuggest(){
      console.log('[fiveTreeSelect] searchOnValue ', this.input);
    },
    */
    setFocusWithDelay(refIdent){
      if(timer.object.renderAfter) clearInterval(timer.object.renderAfter);
      timer.object.renderAfter = setTimeout(
          () => {
            this.$refs[refIdent].focus();
            //this.$refs.treeInput.focus();
            //console.log('[fiveTreeSelect] setFocusWithDelay ', refIdent, this.$refs);
          },
          timer.config.renderAfter
      )
    },

    onSuggestKeydown() {
      if(document.activeElement) {
        //console.log('[fiveTreeSelect] onSuggestKeydown ', document.activeElement);
        document.activeElement.click();
      }
    },


  },
  watch: {
    inputModel: {
      handler(newVal, oldVal) {
        //console.log('[fiveTreeSelect] WATCH  X inputModel', newVal, oldVal);
        if(typeof oldVal != "undefined") {
          this.$emit('update:modelValue', newVal)
        }
      },
      deep: true,
      immediate: true
    },

    'treeList.selected': {
      handler(newVal, oldVal) {
        //console.log('[fiveTreeSelect] watch treeList.selected  [TS]', newVal);
        this.input.isSuggestOpen = false;
        if(newVal) {
          this.selectedValue = newVal
          this.inputModel = newVal.id
          this.input.isValue = true;
        }else{
          this.selectedValue = null
          this.inputModel = null
          this.input.isValue = false;
        }
        this.createDisplayValue();
        this.addOpenToOption(this.options);
      },
      deep: true,
      immediate: true
    },
/*
    'input.isSuggestOpen': {
      handler(newVal, oldVal) {
        console.log('[fiveTreeSelect] [WATCH] input.isSuggestOpen', newVal);
      },
      deep: true,
      immediate: true
    },
*/

  },
}
</script>

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'



//import configApi from './components/config/api.js'
//import configGeneral from './components/config/general.js'
import texts from './components/config/texts.js'
import translated from './components/config/translated-text'
import 'bootstrap-icons/font/bootstrap-icons.css'
import "@/scss/global.scss"
import displayDebug from  "@/components/general/DisplayDebug"


const utils = {};
const requireUtils = require.context('@/utils', false, /\.js$/);
requireUtils.keys().forEach((fileName) => {
    const moduleFunctions = requireUtils(fileName);
    Object.keys(moduleFunctions).forEach(fnName => {
        utils[fnName] = moduleFunctions[fnName];
    });
});

//consts
/*
const consts = {};
const requireConsts = require.context('@/consts', false, /\.js$/);
requireConsts.keys().forEach((fileName) => {
    const moduleFunctions = requireConsts(fileName);
    Object.keys(moduleFunctions).forEach(fnName => {
        consts[fnName] = moduleFunctions[fnName];
    });
});
*/
const consts = {};
const requireConsts = require.context('/conf', false, /\.js$/);
requireConsts.keys().forEach((fileName) => {
    const moduleFunctions = requireConsts(fileName);
    Object.keys(moduleFunctions).forEach(fnName => {
        consts[fnName] = moduleFunctions[fnName];
    });
});




const app = createApp(App)
    .use(store)
    .use(router)
    //.use(configApi)
    //.use(configGeneral)
    //.use(texts)
    .use(consts)
    //.use(conf)
    .mixin(
        {
            data() {
                return {
                    //texts:texts,
                    //translated: translated,
                    /*
                    cfg: {
                        general: configGeneral,
                        api: configApi
                    }
                    */
                }
            },

            methods: {
                ...utils
            }
        }
    );

    app.component('displayDebug', displayDebug);
    //todo axios
    //todo image
    //todo?

    app.config.globalProperties.constsPropName = consts.config.constsPropName;
    app.config.globalProperties[consts.config.constsPropName] = consts;

    const requireDirective = require.context('@/directives', false, /\.js$/);
    requireDirective.keys().forEach(fileName => {
        const directiveName = fileName.replace(/^\.\/(.*)\.\w+$/, '$1')
        const directiveConfig = requireDirective(fileName)
        app.directive(directiveName, directiveConfig.default || directiveConfig)
    })

    app.mount('#app');